.Montserrat24 {
    font: normal normal normal 14px/21px Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    letter-spacing: 0.25px;
    text-align: left;

}

.poppins24 {
    font: normal normal normal 24px/36px Poppins !important;
    letter-spacing: 0.25px;
    text-align: left;
    color: #979797;
    font-weight: 400;
    font-size: 24px;

}

.addButtonn {
    font: normal normal normal 16px/24px InterRegular !important;
    font-weight: 500;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    border-radius: 12px !important;



    background: #076EFF;

    box-shadow: 0px 8px 12px -2px rgba(0, 0, 0, 0.08), 0px 2px 6px -2px rgba(0, 0, 0, 0.06);


}


.formhead {
    font: normal normal normal 36px/24px InterSemiBold !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 24px;
    /* or 67% */
    text-align: center;
    letter-spacing: 0.3px;
    color: #000000;

}

/* Frame 1000003630 */
.form-item {
    position: absolute;
    width: 368px;
    height: 588px;
    left: 66px;
    top: 112px;
    overflow-y: scroll;

}

.label {
    font: normal normal normal 14px/21px InterSemiBold !important;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.25px;
    text-align: left;
    margin-bottom: 20px !important;
    color: #000000;

}

.generalSettings-label1 {
    font: normal normal normal 16px/24px InterSemiBold !important;
    font-weight: 500;
    text-align: left;
    letter-spacing: 0.5px;
    color: #000000;
}

.generalSettings-label2 {
    font: normal normal normal 16px/24px InterRegular !important;
    width: 412px;
    height: 48px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #4E616B;
}

.genaralsettings-cardhead {
    font: normal normal normal 24px/29px Inter !important;
    position: absolute;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
}

.fixed-button-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    padding: 10px 0;
}

.button-wrapper {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.content-container {
    padding-bottom: 60px;
}

.generalsetting-card {
    background: #FFFFFF;
    padding: 1.25rem;
    border: 0 none;
    margin-bottom: 2rem;
    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25), inset -2px -2px 3px 2px rgba(0, 0, 0, 0.04);
    border-radius: 24px;
}

.boxinnershadow-card {
    background: #FFFFFF;
    padding: 1.25rem;
    border: 0 none;
    margin-bottom: 2rem;
    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25), inset -2px -2px 3px 2px rgba(0, 0, 0, 0.04);
    border-radius: 24px;
}

.generalsettings-cardtext {
    font: normal normal normal 16px/19px InterRegular !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}

.button-text {
    font: normal normal normal 16px/24px InterMedium !important;
    width: 32px;
    height: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #FFFFFF;
}

.datatable-head {
    font: normal normal normal 14px/21px Inter !important;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #4E616B;
}

.datatable-cel {
    font: normal normal normal 16px/24px InterSemiBold !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #000000;
}

.feeText {
    font: normal normal normal 16px/24px InterSemiBold !important;
    font-family: 'Inter';
    font-weight: 400;
    letter-spacing: 0.5px;
    text-decoration-line: underline;
    color: #000000;
}

.submitButtonn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    width: 286px;
    height: 48px;
    background: #076EFF;
    box-shadow: 0px 8px 12px -2px rgba(0, 0, 0, 0.08), 0px 2px 6px -2px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.submitButtonnText {
    font: normal normal normal 16px/24px InterSemiBold !important;
    width: 32px;
    height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.cancelButtonn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    width: 182px;
    height: 48px;
    background: #F4F5F6;
    border-radius: 12px;
    flex: none;
    border-width: 0;
    border-color: none;
    background: var(--components-button-secondary-background-default, #F4F5F6);
    // order: 1;
    // flex-grow: 1;
}

.cancelButtonnText {
    font: normal normal normal 16px/24px InterSemiBold !important;
    width: 55px;
    height: 24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #171C1F;
    flex: none;
    order: 0;
    flex-grow: 0;

}

.addSlabTxt {
    font: normal normal normal 16px/24px InterSemiBold !important;
    
    letter-spacing: 0.30000001192092896px;
    text-align: center;
}