.Montserrat24 {
    font: normal normal normal 14px/21px Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    letter-spacing: 0.25px;
    text-align: left;

}

.poppins24 {
    font: normal normal normal 24px/36px Poppins !important;
    letter-spacing: 0.25px;
    text-align: left;
    color: #979797;
    font-weight: 400;
    font-size: 24px;

}

.addButton {
    font: normal normal normal 16px/24px InterSemiBold !important;
    font-weight: 500;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    border-radius: 12px;
    border-style: none;
    border-width: none;
}

.addColumnButton {
    font: normal normal normal 12px/16px InterSemiBold !important;
    letter-spacing: 0.4px;
    font-weight: 500;
    color: #FFFFFF;

    background: #076EFF;

}

.teacherDiaryHead {
    font: normal normal normal 24px/24px InterSemiBold !important;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-decoration-line: underline !important;

    color: #000000;


}



.createtest-card {
    padding: 40px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 4px 4px 4px -4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    margin-bottom: 20px;
}



.add-qustion {


    .question-additonal {

        padding: 20px;
    }


    .question-tags {
        .p-chips-multiple-container {
            padding: 0.35rem 0.5rem;
            background: var(--theme-100) 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px var(--shadow-85);
            border: 0.5px solid var(--theme-85);
            border-radius: 5px;
        }

        max-width: 50%;
    }

    .add-q-label {
        font: normal normal normal 18px/24px InterSemiBold !important;
        font-weight: 500;
        letter-spacing: 0.61px;
        color: #333333;
        text-shadow: 0px 2px 3px #00000029;
        margin-left: 5px;
        // margin-right: 20px;

    }

    .latex-input {
        line-height: 24px !important;
    }


    .key-selector {

        margin-top: 20px;
    }

    .attachment-label {
        margin: 10px;
        font: normal normal normal 11px/17px InterSemiBold !important;
        font-weight: 500;

        letter-spacing: 0px;
        color: #444444;

        i {
            margin: 10px;
        }
    }


    .add-option-label {
        margin: 3px;
        font: normal normal normal 11px/17px InterSemiBold !important;
        font-weight: 500;
        letter-spacing: 0px;
        color: #444444;
    }
}


.ma-label-s2 {
    font: normal normal normal InterBold !important;
    color: var(--shadow-57);

}



.question-bank {
    .qb-tabs {
        margin-bottom: 30px;

        .qb-active-tab {
            cursor: pointer;
            font: normal normal normal 14px/21px InterSemiBold;
            font-weight: 600;
            letter-spacing: 0.09px;
            color: #171725;
            margin-right: 20px;
            padding-bottom: 4px;
            border-bottom: 3px solid var(--primary-color);
        }

        .qb-inactive-tab {
            cursor: pointer;
            font: normal normal normal 15px/23px InterSemiBold;
            letter-spacing: 0.09px;
            color: #BCBCBC;
            margin-right: 20px;
        }

        .egraph {
            margin-right: 10px;
            position: relative;
            top: 5px;
            cursor: pointer;
        }
    }
}


.add-qustion {


    .question-additonal {

        padding: 20px;
    }


    .question-tags {
        .p-chips-multiple-container {
            padding: 0.35rem 0.5rem;
            background: var(--theme-100) 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px var(--shadow-85);
            border: 0.5px solid var(--theme-85);
            border-radius: 5px;
        }

        max-width: 50%;
    }

    .add-q-label {
        font: normal normal bold 18px/24px InterSemiBold;
        letter-spacing: 0.61px;
        color: #333333;
        text-shadow: 0px 2px 3px #00000029;
        margin-left: 5px;
        // margin-right: 20px;

    }

    .latex-input {
        line-height: 24px;
    }


    .key-selector {

        margin-top: 20px;
    }

    .attachment-label {
        margin: 10px;

        font: normal normal normal 11px/17px InterSemiBold;
        letter-spacing: 0px;
        color: #444444;

        i {
            margin: 10px;
        }
    }


    .add-option-label {
        margin: 3px;
        font: normal normal normal 11px/17px InterSemiBold;
        letter-spacing: 0px;
        color: #444444;
    }
}



.question-img {
    max-width: 100%;
    max-height: 200px;
    margin-top: 10px;
    margin-left: 30px;
}


.preview-qo {
    padding: 10px 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    //border-radius: 6px;
    min-height: 120px !important;


}


.preview-alt-ans {

    margin-top: 22px;
}


.p-button-outlined {}

.key-btn {
    padding: 12px 14px;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: bold;
    // color: #333333 !important;
}

.key-btn-active {
    //  color: white !important;
    border-color: #333333;
}


.btn-1234-c {
    width: 40px;
    font-weight: bold;
    font-size: 18px;
    word-break: break-all;
    letter-spacing: 4px;
    line-height: 16px;
    padding: 2px;
    padding-right: 0px;
}

.btn-1234 {
    margin-left: 10px;
    width: 30px;
    font-weight: bold;
    font-size: 14px;
    word-break: break-all;
    letter-spacing: 4px;
    line-height: 12px;
    padding: 3px;
    padding-right: 0px;
}

.numpad-menu {

    width: 292px;
    padding: 0px;
    border-radius: 10px;
    overflow: hidden;

    li {
        overflow: hidden;
    }

    .num-pad {
        border-radius: 10px;
        border: 1px solid #666666;
        overflow: hidden;



        .each-key {
            font: normal normal normal 18px/24px InterSemiBold;
            letter-spacing: 0px;
            color: #333333;
            border: 1px solid rgba(0, 0, 0, 0.1);
            padding: 10px 35px;
            cursor: pointer;
            margin-top: 14px;
        }
    }


    .correct-r {
        border-radius: 50%;
        background-color: var(--primary-color);
        padding: 5px;
        color: white;
    }

    .clear-r {
        border-radius: 50%;
        background-color: var(--shadow-45);
        padding: 5px;
        color: white;
    }
}






.search-opt {
    width: 90%;
    margin: auto;
}


.previous-remove {
    margin-top: 10px;
}


.previous-exam {
    // border: 1px solid rgba(0, 0, 0, 0.1);
    // width: auto;
}




.question-s1 {
    font-size: 22px;
}

.question-s2 {
    font-size: 18px;
}



.preview-paper {
    background-color: white;
    margin: 70px 0px;
    padding: 0px 30px;

    .e-instructions {
        font: normal normal normal 16px/25px InterSemiBold;
        letter-spacing: 0px;
        color: #787878;
    }

    .school-title {
        text-align: center;

    }

    .school-address {
        text-align: center;
        font-weight: bold;
    }


    .e-header {
        border-style: double;
        border-right: none;
        border-left: none;
        height: 56px;
        // border-top: 5px double black;
        // border-bottom:1px double black; 


        .e-date {
            font-weight: bold;
        }
    }

    .subject-name {
        text-align: center;
        text-decoration: underline;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 10px;



    }

    .paper-download-1 {


        .q-label {
            // font: normal normal bold 18px/24px Nunito Sans;
            // letter-spacing: 0.61px;
            // color: #333333;
            // text-shadow: 0px 2px 3px #00000029;
            // margin: 5px;
            // margin-right: 20px;

            font: normal normal normal 18px/27px InterSemiBold;
            font-weight: bold;
            letter-spacing: 0px;
            line-height: 15px;
            color: #242424;
        }

        .question {
            font: normal normal normal 16px/25px InterSemiBold;
            letter-spacing: 0px;
            color: #787878;

        }

        // font-family: 'Poppins', sans-serif;
        font-family: 'Times New Roman',
        Times,
        serif;

        .question-wrapper {

            margin: 10px;

            .num-answer {
                border-bottom: 1px solid rgba(0, 0, 0, 0.5);
                margin-left: 50px;
                width: 200px;
                margin-top: 26px;
                margin-bottom: 0px;

            }

            .question {
                font-size: 18px;
                margin: 0px 15px 0px 15px;
                font-weight: bold;

                span.qNo {
                    margin-right: 8px;
                }
            }

            .option-w {
                width: 50%;
                float: left;
                padding: 20px;

                .option {
                    margin: 4px 15px 4px 50px;
                    font-size: 18px;
                }
            }

            .q-attach {
                margin-left: 100px;
                padding: 10px;
                max-width: 20%;
            }

            .o-attach {
                margin-left: 60px;
                padding: 10px;
                // max-height: 200px;
                max-width: 40%;
            }
        }
    }


    .question-d {
        font: normal normal normal 14px/18px InterSemiBold;
        letter-spacing: 0px;
        color: #787878;
    }

    .option-w {
        padding: 5px !important;
    }
}





.avail-q {
    span {
        font-weight: bold;
        font: normal normal 600 15px/20px InterSemiBold;
        letter-spacing: 0px;
        color: #888888;
    }
}







.questions-d {
    background: var(--theme-100) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--shadow-80);
    border: 0.2px solid #d9d9d9;
    border-radius: 16px;

}