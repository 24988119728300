.adhoc-exam-upload {
  // Dialog customization
  .p-dialog-content {
    padding: 2rem;
    background: #f8f9fa;
  }

  // Tabs styling
  .exam-tabs {
    display: flex;
    border-bottom: 2px solid #e9ecef;
    margin-bottom: 2rem;

    .tab-item {
      padding: 1rem 2rem;
      font-size: 1rem;
      cursor: pointer;
      position: relative;
      color: #6c757d;
      transition: all 0.2s ease;

      &.active {
        color: #076EFF;
        font-weight: 500;

        &:after {
          content: '';
          position: absolute;
          bottom: -2px;
          left: 0;
          width: 100%;
          height: 2px;
          background: #076EFF;
        }
      }

      &:hover:not(.active) {
        color: #495057;
      }
    }
  }

  // Download section
  .download-section {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);

    .exam-name {
      font-size: 1.25rem;
      color: #212529;
      margin-bottom: 1.5rem;
      font-weight: 500;
    }

    .dropdowns-container {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      margin-bottom: 1.5rem;

      .p-dropdown, .p-multiselect {
        min-width: 200px;
      }
    }

    .exam-details {
      background: #f8f9fa;
      padding: 1rem;
      border-radius: 4px;
      margin-bottom: 1.5rem;

      p {
        margin: 8px 0;
        font-size: 1.1em;
        
        strong {
            font-weight: 600;
            margin-right: 8px;
            color: #333;
        }

        .highlight-text {
            color: #2196F3;
            font-weight: 500;
        }

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  // Upload section
  .upload-section {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);

    .spread-title {
      font-size: 1.1rem;
      color: #495057;
      margin-bottom: 1rem;
    }

    .file-upload-container {
      border: 2px dashed #e9ecef;
      padding: 1.5rem;
      border-radius: 8px;
      text-align: center;
      margin: 1rem 0;
      transition: all 0.2s ease;

      &:hover {
        border-color: #076EFF;
      }

      .p-inputtext {
        width: 100%;
        margin-bottom: 1rem;
      }

      .select-file {
        background: #076EFF;
        color: white;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
          background: #0056b3;
        }
      }
    }

    .upload-button {
      margin-top: 1rem;
      width: auto;
    }
  }
}