$gutter: 1rem;

@import 'node_modules/primeflex/primeflex.scss';
@import 'prismjs/themes/prism-coy.css';
@import './AppDemo.scss';



:root {
    --body-background: #eef7fb;
    --primary-color: #0A9EDC;
    --primary-color-dark: #098bc3;
    --primary-color-text: #ffffff;
    --theme-100: #ffffff;
    --theme-97: #f0faff;
    --theme-96: #eef7fb;
    --theme-95: #e7f7fe;
    --theme-90: #ceeffd;
    --theme-85: #b6e7fc;
    --theme-80: #9edffa;
    --theme-77: #89d4fe;
    --theme-75: #85d6f9;
    --theme-70: #6dcef8;
    --theme-65: #55c6f7;
    --theme-60: #3cbef6;
    --theme-55: #24b6f5;
    --theme-50: #0baef4;
    --theme-45: #0a9edc;
    --theme-40: #098bc3;
    --theme-37: #027abc;
    --theme-35: #087aaa;
    --theme-30: #076892;
    --theme-25: #06577a;
    --theme-20: #054661;
    --theme-15: #033449;
    --theme-10: #022331;

    --shadow-100: #ffffff;
    --shadow-95: #f2f2f2;
    --shadow-90: #e6e6e6;
    --shadow-85: #d9d9d9;
    --shadow-80: #cccccc;
    --shadow-75: #bfbfbf;
    --shadow-70: #b3b3b3;
    --shadow-65: #a6a6a6;
    --shadow-60: #999999;
    --shadow-57: #919191;
    --shadow-55: #8c8c8c;
    --shadow-53: #898484;
    --shadow-52: #848484;
    --shadow-50: #808080;
    --shadow-45: #737373;
    --shadow-40: #666666;
    --shadow-35: #595959;
    --shadow-33: #545454;
    --shadow-30: #4d4d4d;
    --shadow-25: #404040;
    --shadow-20: #333333;
    --shadow-15: #262626;
    --shadow-10: #1a1a1a;

    --required: #D32F2F;


    --text-input: #545454;



    --analyitcs-color1: #B7F5FF;
    --analyitcs-color2: #4199AF;
    --analyitcs-color3: #68B1E9;
    --analyitcs-color4: #7EBFD8;
}


.upload-note {
    span {
        color: red;
    }

    margin-top: -22px;
    font: normal normal 500 16px/22px Nunito Sans;
    letter-spacing: 0px;
    color: #4B4B4B;
}


.p-multiselect-label {
    display: block;
    white-space: normal;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
}

.multiselect-wrap {
    // display: flex;
    white-space: normal !important;
    //cursor: pointer;
    // overflow: hidden;
    // text-overflow: ellipsis
}


.max-w-50p {
    width: 50%;
}

.nav-logo-width {
    width: 50px;
    height: 50px;
}

.nav-logo-width-slim {
    width: 32px;
    height: 30px;
}

.pot-table {

    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0px;
    overflow: hidden;



    .p-column-title {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .p-datatable-tbody {}
}

.pot-w120p {

    width: 120px;

}

.pot-w150p {

    width: 150px;

}

.pot-w200p {

    width: 200px;

}

.a {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    // transition: 0.3s;

    transition: all 1s;
    /* lets make it smooth */

    background-image: linear-gradient(#388087, #388087);
    background-size: 0 100%;
    background-repeat: no-repeat;
    height: 200px;
    // transition: .6s;

    h5 {
        margin: auto;
        padding: 0px;
    }
}

.a:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    background-size: 100% 100%;
    background-position: right;
    // background-color: #4e92ff;

    color: #fff;

    transition: all .6s;
    transform: scale(1.2);
}

.smcards {
    transition: all 0.6s;

}

.smcards:hover {
    background-color: rgb(183, 182, 182);
    transform: translateY(-10px);
    color: #fff;

}

.p-breadcrumb {
    background: #f9f7f7;
    border: 0px;
    border-radius: 0px;
    padding: 0rem;
}

.dashboard-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 170px;
    transition: all 0.6s;
    position: relative;
}

.dashboard-cards:hover {
    background-color: #388087 !important;
    transform: translateY(-10px) !important;
    color: #fff;

}

.dashboard-cardbg {
    background-color: #ffffff;
}


.fpot-tree-table {

    tbody {

        td .p-treetable-toggler {
            position: relative;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 15px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;

            &:before {
                position: absolute;
                top: 15px;
                left: 0;
                width: 15px;
                height: 1px;
                margin: auto;
                content: '';
                background-color: #666;
            }

            &:after {
                // position: absolute;

                // top: 0;
                // bottom: 0;
                // left: 0;

                width: 1px;
                height: 100px;
                content: '';
                background-color: #666;
            }

            &:last-child:after {
                height: 15px;
            }
        }

        .p-treetable-toggler {
            position: relative;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 15px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;

            &:before {
                position: absolute;
                top: 15px;
                left: 0;
                width: 10px;
                height: 1px;
                margin: auto;
                content: '';
                background-color: #666;
            }

            &:after {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 1px;
                height: 100%;
                content: '';
                background-color: #666;
            }

            &:last-child:after {
                height: 15px;
            }
        }

        a {
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }

    }

}


.revenue {
    padding-bottom: 87px;
}


.create-fir {

    div.col-6,
    div.col-12 {
        padding: 3px;
    }
}


.hidden-print {
    visibility: 'hidden';
    max-height: '0px';
    height: 10px;
    overflow: scroll;
}

// .backgroundimg3:hover{
//   filter: grayscale(0%);
//   transform: scale(1.05);
//   filter: grayscale(75%);  
// }

// .backgroundimg6:hover{
//     filter: grayscale(0%);
//   transform: scale(1.05);
//   filter: grayscale(75%);

// }

.backgroundimg3 {
    background-color: #bc1f64;
    color: #fff;
    font-size: 20px !important;
}

.backgroundimg2 {
    background-color: gray;
    font-size: 20px !important;
}

.backgroundimg4 {
    background-color: #eb6424;
    color: #fff;
    font-size: 20px !important;
}

.backgroundimg5 {
    background-color: #184e77;
    color: #fff;
    font-size: 20px !important;
}

.backgroundimg6 {
    background-color: #774936;
    color: #fff;
    font-size: 20px !important;
}

.backgroundimg7 {
    background-color: #E96479;
    color: #fff;
    font-size: 20px !important;
}

.icontop {
    position: absolute;
    right: 6px;
    top: 6px;
    margin: 0;
    padding: 0;

}

.backgroundimg {
    background-color: #8f3985;
    color: #fff;
    font-size: 20px !important;
}

.select-file {
    // margin-left: 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid var(--primary-color);
    border-radius: 7px;
    // font: normal normal 600 13px/18px Nunito Sans;
    letter-spacing: 0px;
    color: var(--primary-color);
    padding: 5px 10px;
    margin-top: 20px;
}



@media print {
    .page-break {
        page-break-after: always;
    }

    .p-md-6 {
        width: 50% !important;
    }

    .hours-graph-pie div {
        margin-left: -43px;

    }

    // .hours-graph-bar {
    //     width: 70%;

    // }

    .print-case {
        .cams-w100 {
            width: 100%;
        }

    }

}

.p-error {
    color: #f44336 !important;
}

.dangercase {
    background: rgb(254, 0, 0);
    padding: 5px 8px;
    border-radius: 100%;
    color: white;
}

.normalCase {
    background: rgb(255, 255, 255);
    padding: 5px 8px;
    border-radius: 100%;

}

.widget-overview-box .col-3 {
    border-right: 1px solid #dee2e6;
}

.product-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.product-badgee {
    border-radius: 2px;
    padding: .25em .5rem;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.product-badge.status-cc_no {
    background: #C8E6C9;
    color: #256029;
}

.product-badge.hearing_status {
    background: #C8E6C9;
    color: #256029;
}

.product-badge.status-prc_no {
    background: #FFCDD2;
    color: #C63737;
}

.product-badge.status-sc_no {
    background: #FEEDAF;
    color: #8A5340;
}

.product-badge.status-stc_no {
    background: #f8ffae;
    color: #8a7540;
}

.product-badgee.acquittal {
    background: #C8E6C9;
    color: #256029;
}

.product-badgee.admissions {
    background: #FEEDAF;
    color: #8A5340;
}

.product-badgee.others {
    background: #FFCDD2;
    color: #C63737;
}






.custom-datable {




    .w100px {
        width: 100px;
    }


    .p-datatable-wrapper {

        overflow-y: auto;
    }
}


.charge-sheet-search {
    .searh-item {
        width: 150px;

    }

    @media screen and (max-width:769px) {
        .searh-item-label {

            width: 100px;
            display: inline-block;
        }

    }




}

.border-bottom {
    border-bottom: 3px solid #208cff;
}

.courtName {
    font-size: 13px;
    font-weight: bold;
}

.ma-required {
    color: red;
}

.custom-input2 {
    width: 100%;
    border: 1px solid red;
    padding: 6px;
    background: var(--theme-100) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--shadow-85);
    border: 0.5px solid var(--theme-85);
    border-radius: 5px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

    .left {

        padding: 0px 4px;
        color: var(--shadow-53);
        font-family: inherit;
    }

    .left-b {
        border-right: 1px solid var(--shadow-60);
    }

    .right {
        color: var(--primary-color);
        font-size: 1rem;
        font-family: inherit;

    }

    .input-text {
        border: none;
        font-family: inherit;
        //width: 56%;
        width: 100%;
        // width: 56%;
        outline: none;
        margin-top: -2px;
        //padding-top: 2px;
        padding-left: 6px;
        font-size: 1rem;
        color: var(--text-input) //var(--shadow-53);
    }

    .action-text {
        width: 120px;
    }
}

.file-attach {
    width: 100%;
    height: 90px;

    .space {
        height: 50px
    }

    .down-attache {
        float: right;
        cursor: pointer;
        color: #1DA72B;

    }
}


.outlined-s1 {

    border: 1px solid red;
    padding: 4px 8px;
    background: var(--theme-100) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--shadow-85);
    // border: 0.5px solid var(--theme-85);
    border-radius: 5px;
    color: var(--primary-color);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

}


.ma-pointer {
    cursor: pointer !important;
}

.rect-logo {
    width: 160px;
    height: 40px;
}

.create-org-w {
    min-height: 70vh;

    .logo-rect-text {
        font: normal normal normal 10px/14px Nunito Sans;
        letter-spacing: 0px;
        color: #BEBEBE;

    }

    .create-org-title {
        font: normal normal normal 23px/34px Poppins;
        letter-spacing: 0.14px;
        color: #171725;
        opacity: 0.8;
        font-weight: 600;
        margin-top: 0px;
    }

    .craete-org-subtitle {
        font: normal normal normal 16px/25px Poppins;
        letter-spacing: 0.1px;
        color: var(--primary-color);
        opacity: 0.8;
        font-weight: 600;
        margin-bottom: 5px;

    }
}

.p-datatable {
    .p-datatable-header {
        border: 0px;
    }
}

.doubts-tabs {
    text-align: center;

    .doubts-tab-w {
        padding: 0px 10px;
    }

    p {
        font: normal normal normal 10px/14px InterSemiBold;
        font-size: 18px;
        letter-spacing: 0.25px;
        line-height: 24px;
        color: #000000;
        font-weight: 600;
        margin-bottom: 10px;
        padding: 7px;
    }
}

.tab-active {
    // background: #ffffff 0% 0% no-repeat padding-box;
    // box-shadow: 0px 0px 6px #00000029;
    // border-radius: 10px 10px 0px 0px;

    //border-bottom: 3px solid var(--primary-color);
}

.tab-active-p {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 10px 10px 0px 0px;

    border-bottom: 4px solid var(--primary-color);
}

.custom-input2 {
    width: 100%;
    border: #cbc8c8 1px solid;
    padding: 6px;
    background: var(--theme-100) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--shadow-85);
    // border: 0.5px solid var(--theme-85);
    border-radius: 5px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

    .left {

        padding: 0px 4px;
        color: var(--shadow-53);
        font-family: inherit;
    }

    .left-b {
        border-right: 1px solid var(--shadow-60);
    }

    .right {
        color: var(--primary-color);
        font-size: 1rem;
        font-family: inherit;

    }

    .input-text {
        border: none;
        font-family: inherit;
        //width: 56%;
        width: 100%;
        // width: 56%;
        outline: none;
        margin-top: -2px;
        //padding-top: 2px;
        padding-left: 6px;
        font-size: 1rem;
        color: var(--text-input) //var(--shadow-53);
    }

    .action-text {
        width: 120px;
    }
}


.file-attach {
    width: 100%;
    height: 90px;

    .space {
        height: 50px
    }

    .down-attache {
        float: right;
        cursor: pointer;
        color: #1DA72B;

    }
}


.outlined-s1 {

    border: 1px solid red;
    padding: 4px 8px;
    background: var(--theme-100) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--shadow-85);
    border: 0.5px solid var(--theme-85);
    border-radius: 5px;
    color: var(--primary-color);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

}

.create-org-w {
    min-height: 70vh;

    .logo-rect-text {
        font: normal normal normal 10px/14px Nunito Sans;
        letter-spacing: 0px;
        color: #BEBEBE;

    }

    .create-org-title {
        font: normal normal normal 23px/34px Poppins;
        letter-spacing: 0.14px;
        color: #171725;
        opacity: 0.8;
        font-weight: 600;
        margin-top: 0px;
    }

    .craete-org-subtitle {
        font: normal normal normal 16px/25px Poppins;
        letter-spacing: 0.1px;
        color: var(--primary-color);
        opacity: 0.8;
        font-weight: 600;
        margin-bottom: 5px;

    }
}

.rect-logo {
    width: 150px;
    height: 70px;
}

.choose-file {
    display: flex;
    justify-content: end;
    border: #9696f4 1px solid;
    margin-left: 11rem;
    padding-right: 15px
}

.sqaure-logo {
    width: 40px;
    height: 40px;
}

.paper-s1 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 10px;
}

.tabheadText {
    font: normal normal normal 18px/24px InterSemiBold !important;
    letter-spacing: 0px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #000000;
}

.formHeadingInter {
    font: normal normal normal 36px/24px InterRegular !important;
    font-size: 36px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    color: #000000;
}

.formSubmitButton {
    width: 150px;
    height: 48px;
    padding: 150;
    gap: 0;
    border-radius: 150;
    background: var(--components-button-primary-background-default, #076EFF);
}

.formSubmitButtonText {
    font: normal normal normal 16px/24px InterRegular !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    color: white;
}

.formCancelButton {
    width: 152px;
    height: 48px;
    padding: 150;
    gap: 0;
    border-radius: 150;
    border-width: 0;
    background: var(--components-button-secondary-background-default, #F4F5F6);
}

.formCancelButtonText {
    font: normal normal normal 16px/24px InterSemiBold;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    color: #171C1F;
}
.no-border:focus {
    border: none !important;
    box-shadow: none !important;
  }
  
  .custom-field-focused:focus {
    border:3px solid #8ec7f3 !important; /* Light blue border color */
    box-shadow: 0 0 0 0.3rem #cce4f6 !important; /* Increased shadow with light blue color */
    border-radius: 6px ;
  }

  ::-webkit-scrollbar {
    width: 7px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: #edebeb ;
}

::-webkit-scrollbar-thumb {
    background-color: #bbb9b9; 
    
    border: 2px solid transparent; 
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgb(118, 117, 117); 
}
.confirmDialogAcceptBtn {
    width: 150px;
    height: 48px;

    gap: 0;
    border-radius: 15px;

    background: var(--components-button-primary-background-default, #076EFF);
    box-shadow: 0px 2px 6px -2px #0000000F;
    //styleName: Action Medium/Semibold;
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    color: "#FFFFFF"



  }

  .confirmDialogCancelBtn {
    width: 150px;
    height: 48px;

    gap: 0;
    border-radius: 15px;
    background: var(--components-button-secondary-background-default, #F4F5F6);

    box-shadow: 0px 2px 6px -2px #0000000F;
    //styleName: Action Medium/Semibold;
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 16px;
    border: none;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    color: #000000;



  }

  .ma-hidden {
    visibility: hidden;

}
  
.ma-paper {
    background-color: #fff !important;
    border-radius: 5px;
    background: var(--theme-100) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--shadow-65);
    border-radius: 16px;
    overflow: hidden;
    padding: 10px;
    margin: 5px;

}

