.ma-status {
    display: flex;

    span.ma-status-text {
        margin-top: 6px;
        margin-right: 10px;
        letter-spacing: 0px;
        color: var(--shadow-20);
    }
}


.chapter-add-dialog {
    min-width: 500px;
}