.Montserrat24 {
    font: normal normal normal 14px/21px Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    letter-spacing: 0.25px;
    text-align: left;

}

.poppins24 {
    font: normal normal normal 24px/36px Poppins !important;
    letter-spacing: 0.25px;
    text-align: left;
    color: #979797;

}

.addButton {
    font: normal normal normal 16px/24px Inter;
    font-weight: 500;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
}

.sideHeading {
    font: normal normal normal 16px/24px InterSemiBold;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    color: black;
}

.topicName {
    font: normal normal normal 16px/24px InterRegular !important;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;

}

.interSemibold {
    font: normal normal normal 18px/26px InterSemiBold !important;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.5px;
    text-align: left;
}

.interbold {
    font: normal normal normal 20px/24px InterBold !important;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
}

.selectedResource {
    padding: '0px 24px 0px 24px';
    background-color: '#F8F9FA';
}

/* CSS Class Names */
.body-medium {
    font: normal normal normal 16px/24px InterMedium !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #000; 
   
}

.body-regular {
    font-family: Inter;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #000; 
   
}


/* CSS Class Name */
.body-small-semibold {
    font: normal normal normal 15px/21px InterSemiBold !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.25px;
    text-align: left;
    /* Specify your color block */
    color: #000000; /* Example color */
    /* Additional styles as needed */
}


.body-small-semibold-color {
    font: normal normal normal 14px/21px InterSemiBold ;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: #667A85;
    text-align: left;
    line-height: 21px;
    flex: 0 0 4rem; /* Specify flex property */
}


.body-medium-semibold {
    font: normal normal normal 16px/21px InterSemiBold!important;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #000000;
    /* Additional styles as needed */
    width: 3rem; /* Example width */
}

.body-medium-color {
    font: normal normal normal 16px/21px InterMedium !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #667A85; 
   
}

.input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.input-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* Ensures the icon doesn't block input field interactions */
    color: #667A85; /* Example color */
}

.input-field {
    width: 100%;
    padding-right: 30px; /* Add padding to avoid text overlapping with the icon */
}


.dataTableValuesText {
    //styleName: Body Medium/Semibold;
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    color: black !important
  }/* Add this CSS to your stylesheet */


  .addBulk {
    font: normal normal normal 16px/24px InterRegular !important;
    font-weight: 500;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    border-radius: 12px !important; 



background: #076EFF;

box-shadow: 0px 8px 12px -2px rgba(0, 0, 0, 0.08), 0px 2px 6px -2px rgba(0, 0, 0, 0.06);


}

.flex-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 24px; /* Aligns child elements to the right */
}

.right-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal normal normal 16px/24px InterRegular !important;
    font-weight: 500;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    border-radius: 12px !important; 



background: #076EFF;

box-shadow: 0px 8px 12px -2px rgba(0, 0, 0, 0.08), 0px 2px 6px -2px rgba(0, 0, 0, 0.06);
}


.formUploadButton {
    width: 200px;
    height: 48px;
    padding: 150;
    gap: 0;
    border-radius: 10px;
    
    background: var(--components-button-primary-background-default, #076EFF);
     justify-content: center;

}

.formUploadButtonText {
    font: normal normal normal 16px/24px InterRegular !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    color: white;
}
.formUploadButton:hover {
    background: var(--components-button-secondary-background-default, #076EFF); /* Same as default background */
}

.formBulkButton {
    width: 240px;
    height: 48px;
    padding: 150;
    gap: 0;
    border-radius: 10px;
    background: var(--components-button-secondary-background-default, #F4F5F6);
    border: 1px solid transparent; /* Ensures no border is visible */
    justify-content: center;
}
.formBulkButton:active,
.formBulkButton:hover {
    background: var(--components-button-secondary-background-default, #F4F5F6 ) !important; 
    border: 1px solid #E6E8EA  !important; /* Changes border color on click */
    opacity: 1 !important;
}

.formBulkButtonText {
    font: normal normal normal 16px/24px InterRegular !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    color: #000;
}


.selectFile {
    width: 160px !important;
    height: 60px !important;
    font-size: 16px;
    font-weight: 500 !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #076EFF;
    border-radius: 7px;
    font: normal normal normal 16px/24px InterRegular !important;
    letter-spacing: 0px;
    color:#076EFF;
    padding: 12px 12px;
}



.formShowButton {
    width: 150px;
    height: 40px;
    padding: 150;
    gap: 0;
    border-radius: 10px;
    
    background: var(--components-button-primary-background-default, #076EFF);
     justify-content: center;

}

.formShowButtonText {
    font: normal normal normal 16px/24px InterRegular !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    color: white;
}
.formShowButton:hover {
    background: var(--components-button-secondary-background-default, #076EFF); /* Same as default background */
}