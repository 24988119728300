.w-5 {
    width: 5%;
}

.w-6 {
    width: 6%;
}

.w-7 {
    width: 7%;
}

.w-8 {
    width: 8%;
}

.w-9 {
    width: 9%;
}

.w-10 {
    width: 10%;
}
.w-11 {
    width: 11%;
}
.w-12 {
    width: 12%;
}
.w-13 {
    width: 13%;
}
.w-14 {
    width: 14%;
}
.w-15 {
    width: 15%;
}


.qb-active-tab {
    cursor: pointer;
    font: normal normal normal 14px/21px Poppins;
    font-weight: 600;
    letter-spacing: 0.09px;
    color: #171725;
    margin-right: 20px;
    padding: 10px;
    // border-bottom: 3px solid var(--primary-color);
}

.qb-inactive-tab {
    cursor: pointer;
    font: normal normal normal 15px/23px Poppins;
    letter-spacing: 0.09px;
    color: #4c4b4b;
    margin-right: 20px;
}

.continue-btn {
    display: flex;
    flex-direction: row-reverse;
}
