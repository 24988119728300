.branch-master {


    .level-create {
        background-color: white;
        padding: 20px;
        margin: 0px 20px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 16px;
        max-height: 80vh;
        min-height: 80vh;
        overflow: hidden;

        .level-wr {
            min-width: 360px;
        }
        .l-title {
            font: normal normal 600 22px/33px Poppins;
            letter-spacing: 0.14px;
            color: #171725;
            opacity: 0.8;
            margin-top: 5px;

        }

        .level-cre {
            display: flex;


            .level-lable {
                font: normal normal bold 12px/16px Nunito Sans;
                letter-spacing: 0px;
                color: #454551;
                margin-top: 9px;
                margin-right: 10px;
            }
        }
    }

    .branch-tree {
        background-color: white;
        //padding: 10px;
        margin: 0px 20px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 16px;
        min-height: 80vh;
        max-height: 80vh;
        overflow: auto;
    }

} 
