.branchwise-acc {

    .level-name {
        text-align: center;
        font: normal normal bold 18px/24px Open Sans;
        letter-spacing: 0px;
        color: #242424;
    }

    p {
        margin: 0px;
    }

    .total-timespent {
        text-align: start;
        font: normal normal bold 20px/27px Open Sans;
        letter-spacing: 0px;
        color: #0A9EDC;

        .total-time-label {
            text-align: start;
            font: normal normal 600 12px/17px Open Sans;
            letter-spacing: 0px;
            color: #b3b3b3;
            margin-right: 20px;
            margin-right: 20px;
            position: relative;
            top: -5px;

        }
    }

    .p-accordion-header-link {
        display: block;
        padding: 0px !important;

    }


    .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
        position: relative;
        top: 22px;
        left: 10px;
    }

    .p-treetable .p-treetable-tbody>tr>td {
        padding: 0px;
    }


    .content-label {
        font: normal normal 600 13px/18px Open Sans;
        letter-spacing: 0px;
        color: #242424;
        margin: 10px;
        position: relative;
        top: -8px;
    }

    .content-value {
        font: normal normal 600 16px/22px Open Sans;
        letter-spacing: 0px;
        color: #0A9EDC;
        position: relative;
        top: -8px;
        margin-left: 10px;
    }

    .p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler {
        top: 16px;
    }
}

.overall-usage-txt {
    font: normal normal 600 22px/33px Poppins;
    letter-spacing: 0.14px;
    color: #4E4E4E;
    margin: 5px;
    margin-top: 16px;
}