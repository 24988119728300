/* Remove default row selection highlight */
.dataTableValuesTextyy .p-row-select {
    background-color: transparent!important; /* Removes background color on row selection */
    box-shadow: none!important;
    opacity: 0px;/* Removes any shadow effect */
}

/* Remove default hover effect if needed */
.dataTableValuesTextyy .p-row:hover {
    background-color: transparent!important;
    opacity: 0px; /* Removes background color on hover */
}

/* Ensure no additional styles applied */
.dataTableValuesTextyy .p-row {
    border: none!important;
    opacity: 0px; /* Removes any border if needed */
}


.Montserrat24 {
    font: normal normal normal 14px/21px Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    letter-spacing: 0.25px;
    text-align: left;

}

.poppins24 {
    font: normal normal normal 24px/36px Poppins !important;
    letter-spacing: 0.25px;
    text-align: left;
    color: #979797;
    font-weight: 400;
    font-size: 24px;

}

.addButtonTeacher {
    width: 201px;
    height: 56px;
    gap: 0;
    border-radius: 14px; 
    background: var(--components-button-primary-background-default, #076EFF);
    box-shadow: 0px 2px 6px -2px #0000000F;
    font: normal normal normal 18px/24px InterSemiBold;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    color: "#FFFFFF";
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
}


.addColumnButton {
    font: normal normal normal 14px/16px InterSemiBold !important;
    font-weight: 500;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    border-radius: 10px;
    border-style:none;
    border-width: none;
    height: 40px;
}

.teacherDiaryHead{
    font: normal normal normal 24px/24px InterSemiBold !important;
    font-weight: 600;
    letter-spacing: 0.5px;
 text-decoration-line: underline !important;

 color: #000000;


}


.attachment-container {
    border: 1px solid #ccc;
    font-family: 'InterSemiBold';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    padding: 10px;
    //margin: 10px;
   // font-family: Arial, sans-serif;
    //width: 200px;
}
.text-on-top {
    margin-bottom: 10px;
    font: normal normal normal 14px/24px InterSemiBold !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}
.attachment-div {
    display: flex;
    align-items: center;
    justify-content: center;
}
.attachment-icon {
    margin-right: 10px;
    font-size: 18px;
}
.attachment-file {
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
}


.attachment-container:hover {
    background-color: transparent; /* Slightly lighter blue background on hover */
    border-color: #007bff; /* Border color on hover */
}

/* Highlight effect (clicked) */
.attachment-container.highlighted {
    background-color: transparent; /* Light blue background for highlighting */
    border-color: #007bff; /* Change border color to blue */
}




.add-diary-field-input{
    //styleName: Body Medium/Regular;
    font: normal normal normal normal InterSemiBold;
    
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    gap: 0px;
    
    color: #000000;
    }









    .submitButtonDiary {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px;
        width: 286px;
        height: 48px;
        background: #076EFF;
        box-shadow: 0px 8px 12px -2px rgba(0, 0, 0, 0.08), 0px 2px 6px -2px rgba(0, 0, 0, 0.06);
        border-radius: 12px;
        flex: none;
        order: 0;
        flex-grow: 0;
    }
    
    .submitButtonnTextDiary {
        font: normal normal normal 16px/24px InterSemiBold !important;
        width: 32px;
        height: 24px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
    }
    
    .cancelButtonnDiary {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px;
        width: 182px;
        height: 48px;
        background: #F4F5F6;
        border-radius: 12px;
        flex: none;
        border-width: 0;
        border-color: none;
        background: var(--components-button-secondary-background-default, #F4F5F6);
        // order: 1;
        // flex-grow: 1;
    }
    
    .cancelButtonnTextDiary {
        font: normal normal normal 16px/24px InterSemiBold !important;
        width: 55px;
        height: 24px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #171C1F;
        flex: none;
        order: 0;
        flex-grow: 0;
    
    }

    .uploadButtonnDiary {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px;
        width: 182px;
        height: 48px;
        background: #F4F5F6;
        border-radius: 12px;
        flex: none;
        border-width:1px;
        border-color: #c8c8c8;
        background: var(--components-button-secondary-background-default, #F4F5F6);
      
    }
    
    .uploadTextDiary {
        font: normal normal normal 16px/24px InterSemiBold !important;
        width: 55px;
        height: 24px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #171C1F;
        flex: none;
        order: 0;
        flex-grow: 0;
    
    }

    /* CSS for the disabled state */
.disabled-input .disabled-icon {
    color: #a0a0a0; /* Disabled color */
}

.button-style {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    background-color: #007bff; /* Primary button color */
    border: 2px solid #007bff; /* Border color matching button color */
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.3s, border-color 0.3s;
}

.button-style:hover {
    background-color: #0056b3; /* Darker shade for hover effect */
    border-color: #0056b3;
}

.button-style:active {
    background-color: #004080; /* Even darker shade for active effect */
    border-color: #004080;
}

/* MyComponent.css */
.uploaded-file-name {
    margin-top: 10px; /* Space between the upload button and file name */
    padding: 10px; /* Padding around the file name text */
    border: 1px solid #ddd; /* Light border around the text */
    border-radius: 4px; /* Rounded corners */
    background-color: #f9f9f9; /* Light background color */
    font-size: 14px; /* Font size for the file name */
    color: #333; /* Text color */
    font-weight: bold; /* Make the text bold */
    display: inline-block; /* Ensure it doesn’t span the full width */
  }
  