.enquiry-header {
    margin-left: 5px;
    font: normal normal 600 16px Poppins;
    letter-spacing: 0.14px;
    color: #171725;
}

.receipt-image {
    padding: 30px 60px;
}

.receipt-heading {
    justify-content: center;
    text-align: center;

}

.receipt-main-heading {
    justify-content: center;
    text-align: center;
}

.heading-font {
    margin: 0 auto;
    letter-spacing: 0.14px;
    color: #171725;
}

.ml-auto {
    margin-left: auto;
}

.mr-auto {
    margin-right: auto;
}

.dashed-line {
    border-top: 1px dashed red;
}

.dashed {
    border-style: dashed;
}

.p-ml-10 {
    margin-left: 10%;
}

.p-ml-20 {
    margin-left: 20%;
}

.w-70 {
    width: 70%;
}

.w-10 {
    width: 10%;
}

.table-border1 {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: left;

    th,
    td {
        padding: 15px;
    }

    .total {
        text-align: right;
    }

}

.table-border {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: left;

    th,
    td {
        padding: 15px;
    }

}
.manager {
    text-align: right;
    margin-top: 4%;
    width: 80%;
}

.li-img-icon {
    height: 24px;
    width: 26px;
    margin-top: -3px;
    margin-right: 4px;

}

.select-file {
    margin-left: 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid var(--primary-color);
    border-radius: 7px;
    font: normal normal 600 13px/18px Nunito Sans;
    letter-spacing: 0px;
    color: var(--primary-color);
    padding: 8px 13px;
    margin-top: 20px;
}

.capture {
    padding: 8px 13px;
    margin-top: 3px;
}

.pt-2 {
    padding-top: 20%;
}

.photo {
    width: 10%;
    height: 80%;
    margin-left: 20px;
}

.image-div {
    border-radius: 5px solid black;
}

.logo-img {
    width: 170px;
    height: 60px;
    margin-left: 50%;
}

.address{
    word-break: break-all;
}

.reciept-details {
    min-width: 100%;
    padding-left: 10%;
}

@media screen and (max-width: 1440px) and (min-width : 930px) {
    .address{
        word-break: break-all;
        margin-left: 10%;
        margin-right: 10%;
    }
}
@media screen and (max-width: 929px) {
    .address{
        word-break: break-all;
        margin-left: 13%;
    }
}

// @media print {


    .logo-img {
        width: 120px;
        height: 40px;
        margin-left: 20%;
        margin-top: 10px;
    }

    .receipt-image {
        position: relative;
        // width: 10%;
        height: 5%;
        padding: 0px;
        margin-top: 10px;
    }

    .receipt-main-heading {
        // position: absolute;
        // margin-left: 10%;
        margin-top: 0px;
        justify-content: center;
        text-align: center;


        h2 {
            font-size: large;
            padding: 0px 0px 0px 0px;
            margin: 0px 0px -50px 0px;
        }
    }

    .address {
        word-break: break-all;
        margin-left: 10%;
        // padding: 0px 0px 0px 0px;
        // margin: 0px 0px 0px 0px
    }

    .receipt-heading {
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;
    }

  
    .dashed {
        margin-top: 2%;
        margin-bottom: 0px;
    }

    .heading-font {
        font-size: x-small;
        padding: 0px;
        margin: 0px;
    }

    .reciept-details {
        min-width: 100%;
        padding: 0px 0px 0px 0px;
        margin: 0px 0px -20px 10px;

        div {
            padding: 0px 0px 0px 0px;
            margin: 2px 0px 2px 0px;
        }
    }

    .table-border1 {
        padding: 0px 0px 0px 0px;
        margin: 0px 0px 0px 0px;

        border: 1px solid black;
        border-collapse: collapse;
        text-align: left;
        width: 100%;

        th,
        td {
            padding: 5px;
        }

        .total {
            text-align: right;
        }
    }

    .manager {
        text-align: right;
        margin-top: 4%;
        width: 100%;
    }


//}