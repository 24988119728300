.section-accordion .p-accordion-header .p-accordion-header-link {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 4px 4px 4px -4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    border: none;

}

.section-accordion .p-accordion-header .p-highlight {
    box-shadow: none;
}

.section-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: #FFFFFF;
    box-shadow: 3px 0px 3px rgba(0, 0, 0, 0.25), -3px 0px 3px rgba(0, 0, 0, 0.25);
    border: none;
    border-bottom: 1px solid black;
    border-radius: 0px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.section-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
    border-radius: 12px;
}


.section-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
    border-radius: 12px;
    margin: 0px 12px 8px 12px;
    height: 42px
}

.section-accordion .p-accordion-tab .p-accordion-content {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    margin: -5px 12px 10px 12px;
    background: #FFFFFF;
    border: none;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25), 3px 3px 3px -3px rgba(0, 0, 0, 0.25);
}


.section-accordion .p-accordion-header-text {
    font-family: 'InterSemiBold';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: #000000;

}

.section-accordion .question-type-label {

    padding: 12px;
    gap: 8px;
    // width: 70px;
    height: 48px;
    background: #FFFFFF;
    border-right: 1px solid #E6E8EA;
    border-radius: 8px 0px 0rgb(153, 153, 153);
    font-family: 'InterRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #667A85;
    

}

.section-accordion .question-type-input {
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 12px;
    background: #FFFFFF;
    border: 1px solid #E6E8EA;
    border-radius: 0px 8px 8px 0px;
    width: 268px;
}
.inter500semibold {
    font: normal normal normal 16px/24px InterSemiBold;
    font-weight: 500;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
    color: black;
}