.dataTableValuesTextt .p-datatable-thead>tr>th {
    background-color: #e6e6e6 !important;
    border: none !important;
    border-bottom: 1px solid #a0bbd7 !important;
    font-family: 'InterSemiBold';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: #4E616B;
    border-right: 2px solid #c5c5c5 !important;
    border-bottom: 2px solid #a0bbd7 !important;

}
.headin{
    font-family: 'InterSemiBold';
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #000000;
}

.dataTableValuesTextt .p-datatable-tbody>tr>td {

    // padding: 16px 24px;
    gap: 16px;
    height: 124px;
    background-color: #E6E8EA !important;
    border-right: 2px solid #c5c5c5 !important;
    border-bottom: 2px solid #a0bbd7 !important;
    font-family: 'InterSemiBold';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.5px;
    white-space: pre-line;

}

.dataTableValuesTextt .p-datatable-tbody>tr>td:last-child {
    border-right: none !important;
}

.dataTableValuesTextt .p-datatable-thead>tr>th:last-child {
    border-right: none !important;
}

