.add-special-route-btn {
    width: 250px;
    height: 56px;
    padding: var(--space-200);
    gap: var(--space-0);
    border-radius: 15px;
    background: var(--components-button-primary-background-default, #076eff);
    box-shadow: 0px 2px 6px -2px #0000000f;
    box-shadow: 0px 8px 12px -2px #00000014;
    animation-duration: 0ms;
    font: normal normal normal normal InterSemiBold;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: center;
}
.transport-header{
    font-family: 'InterSemiBold';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #000000;
    }