.assignFeeCard2 {
    background: var(--background-elevation-2, #FFFFFF);
    width: 380px;
    height: 160px;
    gap: var(--space-300);
    border-radius: 20px;
    
    box-shadow: -2px -2px 3px 2px #0000000A inset;
    box-shadow: 2px 2px 4px 1px #00000040 inset;


}


.addBoardButtonText{
    font: normal normal normal 16px/24px InterSemiBold !important;

}
.addBoardButton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    
    position: absolute;
    width: 208px;
    height: 48px;
    left: 38px;
    top: 475px;
    
    background: #F4F5F6;
    border-radius: 12px;
}

.ttext{
    font: normal normal normal 16px/24px Inter !important;

}


