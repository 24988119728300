.mainTabText {
    //styleName: Action Large/Semibold;
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: center;
    color: #000000;
    width: 168px;
    height: 24px;
    gap: 0px;
    

}

.doubts-tabs {
    text-align: center;

    .doubts-tab-w {
        padding: 0px 10px;
    }

}

.tabPara {
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 18px;
    letter-spacing: 0.25px;
    line-height: 24px;
    color: #000000;
    font-weight: 600;
    margin-bottom: 10px;
    padding: 7px;
}

.tabContainer {
    width: 332px;
    height: 56px;
    padding: var(--space-200) var(--space-300) var(--space-200) var(--space-300);
    gap: var(--space-100);
    border: 0px 0px 2px 0px;
    
    border-bottom: 2px solid var(--stroke-brand-medium, #076EFF)
}

.subCardMainText {
    font: normal normal normal 10px/14px Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    letter-spacing: 0.25px;
    text-align: left;
    width: 99px;
    height: 29px;
    color: #000000;
    gap: 0px;
    

}

.subCardContainer {
    height: 144px;
    gap: 0px;
    
    background: #FFFFFF;
    box-shadow: 0px 2px 2px 0px #00000040;


}

.subCardParaText {
    font: normal normal normal 14px/20px Poppins;
    // font-family: "Poppins";
    width: 70px;
    height: 36px;

    gap: 0px;
    

    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.25px;
    text-align: center;
    color: #979797;
}

.saveBtn {
    width: 107px;
    height: 56px;
    gap: var(--space-0);
    border-radius: 10px;
    
    background: var(--components-button-primary-background-default, #076EFF);
    box-shadow: 0px 2px 6px -2px #0000000F;

    box-shadow: 0px 8px 12px -2px #00000014;
    //styleName: Action Large/Semibold;
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: center;
    color: #FFFFFF;
}

.toggleSwitchHead {
    //styleName: Body Medium/Semibold;
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    width: 200px;
    height: 24px;
    color: #000000;

}

.toggleSwitchPara {
    //styleName: Body Medium/Regular;
    // font: normal normal normal 10px/14px InterSemiBold;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #4E616B;
    width: 268px;
    height: 48px;
    gap: 0px;
    

}

.dateTextLabel {
    //styleName: Body Small / Semibold;
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.25px;
    text-align: left;
    gap: 0px;
    
    color: #000000;

}

.selectBoardsContainer {
    width: 1206px;
    height: 255px;
    gap: var(--space-300);
    border-radius: 20px;
    
    background: var(--background-elevation-2, #FFFFFF);
    box-shadow: -2px -2px 3px 2px #0000000A inset;

    box-shadow: 2px 2px 4px 0px #00000040 inset;

}

.selectBoardsHeading {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: center;
    width: 275px;
    height: 29px;
    gap: 0px;
    
    color: #000000;
}

.validationsHeading {
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    width: 317px;
    height: 24px;
    text-decoration: underline;
    gap: 0px;
    
    color: #000000;
}

.validationsText {
    width: 174px;
    height: 24px;
    gap: 0px;
    
    //styleName: Body Medium/Semibold;
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #000000;
}

.ageCriteriaSaveBtn {
    width: 107px;
    height: 56px;
    padding: var(--space-200);
    gap: var(--space-0);
    border-radius: 12px;
    
    box-shadow: 0px 2px 6px -2px #0000000F;
    //styleName: Action Large/Semibold;
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: center;
    gap: 0px;
    
    color: #FFFFFF;
    box-shadow: 0px 8px 12px -2px #00000014;
    background: var(--components-button-primary-background-default, #076EFF);

}

.calenderBoxSize {
    width: auto;
    height: auto;
    padding: var(--space-300);
    gap: var(--space-150);
    border-radius: 12px;
    
    background: var(--background-elevation-2, #FFFFFF);
    box-shadow: 0px 4px 8px -2px #0000000A;

    box-shadow: 0px 16px 24px -4px #00000014;

}

.calenderApplyBtn {
    width: 136px;
    height: 40px;
    gap: var(--space-0);
    border-radius: 12px;
    
    background: var(--components-button-primary-background-default, #076EFF);

    //styleName: Action Small/Semibold;
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.4000000059604645px;
    text-align: center;
    color: #FFFFFF;
    box-shadow: 0px 2px 6px -2px #0000000F;

    box-shadow: 0px 8px 12px -2px #00000014;

}

.calenderCancelBtn {
    width: 136px;
    height: 40px;
    gap: var(--space-0);
    border-radius: 12px;
    
    background: var(--components-button-secondary-background-default, #F4F5F6);
    //styleName: Action Small/Semibold;
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    border: none;
    letter-spacing: 0.4000000059604645px;
    text-align: center;
    color: #171C1F;
    box-shadow: 0px 2px 6px -2px #0000000F;

    box-shadow: 0px 8px 12px -2px #00000014;

}

.admissionCategoryCardContainer {
    width: 338px;
    height: 191px;
    top: 280px;
    left: 138px;
    gap: 0px;
    
    box-shadow: 0px 2px 2px 0px #00000040;
    color: #FFFFFF;
}

.categoryCardHeadText {

    font: normal normal normal 10px/14px Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    letter-spacing: 0.25px;
    text-align: left;
    color: #000000;
    //  width: 147px;
    //  height: 29px;

}

.categoryCardPara {
    // font-family: 'Poppins';
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.25px;
    text-align: left;
    width: 150px;
    height: 36px;
    gap: 0px;
    
    color: #979797;

}

.addAdmissionBtn {
    width: 284px;
    height: 56px;
    padding: var(--space-200);
    gap: var(--space-0);
    border-radius: 12px;
    
    background: var(--components-button-primary-background-default, #076EFF);
    // On click
    // Open overlay: "Add Admission Category";
    // Animate: Move in Right;
    animation-timing-function: ease-out;
    animation-duration: 300ms;
    box-shadow: 0px 2px 6px -2px #0000000F;

    box-shadow: 0px 8px 12px -2px #00000014;
    //styleName: Action Large/Semibold;
    font: normal normal normal normal InterSemiBold;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: center;

}

.addRegistrationBtn {
    width: 355px;
    height: 56px;
    padding: var(--space-200);
    gap: var(--space-0);
    border-radius: 12px;
    
    background: var(--components-button-primary-background-default, #076EFF);
    // On click
    // Open overlay: "Add Admission Category";
    // Animate: Move in Right;
    animation-timing-function: ease-out;
    animation-duration: 300ms;
    box-shadow: 0px 2px 6px -2px #0000000F;

    box-shadow: 0px 8px 12px -2px #00000014;
    //styleName: Action Large/Semibold;
    font: normal normal normal normal InterSemiBold;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: center;
    color: #FFFFFF;

}

.addAdmissionDilogBox {
    width: 500px;
    height: 800px;
    gap: 0px;
    border-radius: 8px 0px 0px 0px;
    

}

.addAdmissionDilogBoxNameField {
    width: 368px;
    height: 48px;
    gap: 0px;
    border: none;
    border-radius: 8px;
    
    box-shadow: 0px 1px 2px 0px #00000014;
    //styleName: Body Medium/Regular;
    // font-family: Inter;
    // font: normal normal normal normal InterSemiBold;
    // font-size: 16px;
    // font-weight: 400;
    // line-height: 24px;
    // letter-spacing: 0.5px;
    // text-align: left;
    // color: #000000;

}

.addAdmissionDilogBoxdesc {
    width: 368px;
    height: 96px;
    border-radius: 8px;
    border: 1px 0px 0px 0px;
    
    background: var(--components-input-background-default, #FFFFFF);
    border: 1px solid var(--components-input-stroke-default, #E6E8EA)
}

.addCardSize {
    width: 420px;
    height: 178px;
    border-radius: 20px;
    
    background: var(--background-elevation-2, #FFFFFF);
    box-shadow: -2px -2px 3px 2px #0000000A inset;

    box-shadow: 2px 2px 4px 0px #00000040 inset;

}

.dialogCardInField {
    width: 343px;
    height: 48px;
    
    gap: 0px;
    border-radius: 8px;
    
    box-shadow: 0px 1px 2px 0px #00000014;

}

.addBoardBtn {
    width: 139px;
    height: 48px;
    padding: var(--space-150);
    gap: var(--space-0);
    border-radius: 12px;
    
    background: #F4F5F6;
    // On click
    // Swap overlay: "Add Admission Category/Add board";
    // Animate: Smart animate;
    animation-timing-function: ease-out;
    animation-duration: 300ms;
    //styleName: Action Medium/Semibold;
    font: normal normal normal normal InterSemiBold;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    color: #171C1F;

}

.addAdmissionDialogAceeptBtn {
    width: 286px;
    height: 48px;
    padding: var(--space-150);
    gap: var(--space-0);
    border-radius: 12px;
    
    background: var(--components-button-primary-background-default, #076EFF);
    box-shadow: 0px 2px 6px -2px #0000000F;

    box-shadow: 0px 8px 12px -2px #00000014;
    //styleName: Action Medium/Semibold;
    font: normal normal normal normal InterSemiBold;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    color: #FFFFFF;


}

.addAdmissionDialogCancelBtn {
    width: 182px;
    height:48px;
    padding: var(--space-150);
    gap: var(--space-0);
    border-radius: 12px;
    
    background: var(--components-button-secondary-background-default, #F4F5F6);
    // On click
    // Action: "Close overlay";
    //styleName: Action Medium/Semibold;
    font: normal normal normal normal InterSemiBold;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    color: #171C1F;
}
.enquiryBoardDropdown {
    width: 343px;
    height: 48px;
    padding: var(--space-150);
    gap: var(--space-150);
    border-radius: 12px;
    border: 1px 0px 0px 0px;
    
    background: var(--components-input-background-default, #FFFFFF);
    border: 1px solid var(--components-input-stroke-default, #E6E8EA);//styleName: Body Medium/Regular;
    font: normal normal normal normal InterSemiBold;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
}
.custom-desc-focused:focus {
    border:3px solid #8ec7f3 !important; /* Light blue border color */
    box-shadow: 0 0 0 0.3rem #cce4f6 !important; /* Increased shadow with light blue color */
    border-radius: 6px ;
  }
