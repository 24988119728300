.ma-success-msg {
    color: #04aa6d;
    font: normal normal 600 14px/19px Nunito Sans;
}

.ma-error-msg {
    font: normal normal 600 14px/19px Nunito Sans;
    color: red;
    margin: 2px;
}
