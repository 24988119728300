.e-paper {
    background-color: white;
    margin: 0px 0px;
    padding: 0px 30px;
    font-size: 13px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    background-color: white;
    .e-instructions {
        padding: 0.75rem 1.5rem;
        background-color: rgba(245, 245, 245, 0.5);
        border-radius: 4px;
        margin-bottom: 1.5rem;
        border-left: 1px solid #ddd;
    }

    .school-title {
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: 0.25rem;
        text-align: center;
        color: #333;
    }

    .school-address {
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        font-size: 1.2rem;
        margin-bottom: 1.5rem;
        text-align: center;
        color: #555;
    }


    .e-header {
        border-style: solid;
        border-width: 1px 0;
        border-color: #ddd;
        height: auto;
        font-family: 'Poppins', sans-serif;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.75rem 1rem;
        background-color: #f9f9f9;
        border-radius: 0;
        margin-bottom: 1rem;

        .e-date {
            font-weight: bold;
            font-size: 13px;
            font-family: 'Poppins', sans-serif; 
            font-weight: 600;
            color: #444;    
        }
    }

    .subject-name {
        text-align: center;
        text-decoration: underline;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 10px;
        font-size: 1.4rem;
        font-weight: 700;
        color: #2c3e50;
        margin-top: 2rem;
        margin-bottom: 0.5rem;
        padding-left: 0;
        border-left: none;



    }

    .paper-download {
        margin-top: 1.5rem;
        // font-family: 'Poppins', sans-serif;
        font-family: 'Times New Roman', Times, serif;

        .question-wrapper {
            margin-bottom: 1.5rem;
            padding: 0.75rem;
            border-radius: 4px;
            background-color: #fff;
            break-inside: avoid; // For modern browsers
            page-break-inside: avoid; // For older browsers

            .num-answer {
                border-bottom: 1px solid rgba(0, 0, 0, 0.5);
                margin-left: 50px;
                width: 200px;
                margin-top: 26px;
                margin-bottom: 0px;

            }

            .question {
                font-size: 13px;
                margin: 0px 15px 0px 15px;
                font-weight: bold;
                font-size: 1.1rem;
                color: #333;
                margin-bottom: 0.75rem;
                line-height: 1.5;

                span.qNo {
                    margin-right: 8px;
                    font-weight: 600;
                    margin-right: 0.5rem;
                }
            }

            .option-w {
                width: 50%;
                float: left;

                .option {
                    margin: 4px 15px 4px 50px;
                    font-size: 13px;
                }
            }

            .q-attach {
                margin-left: 100px;
                padding: 10px;
                max-width: 20%;
            }

            .o-attach {
                margin-left: 60px;
                padding: 10px;
                // max-height: 200px;
                max-width: 40%;
            }
        }
    }
}

.exam-instruction{
    margin-top: 24px;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    color: #2c3e50;
    padding-left: 0.5rem;
    border-left: 4px solid #9b59b6;

}

.e-exam-name {
    text-align: center;
    font-size: 1.6rem;
    font-weight: 700;
    margin: 1.5rem 0;
    color: #1a1a1a;
    padding: 0.5rem 1rem;

    span {
        border: none;
        border-radius: 0;
        background-color: transparent;
        padding: 5px 10px;
        position: relative;
        display: inline-block;
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: -4px;
            left: 0;
            background-color: #555;
        }
    }
}


.sp-right {
    float: right;
}

.sp-no-pm {
    margin: 0px;
    padding: 0px;
    margin: 0.25rem 0;
}



.sp-clearFix {
    clear: both;
}


.sp-mt40 {
    margin-top: 40px !important;
}


.e-hr {
    color: black;
}



.sp-ml10 {

    margin-left: 10px;
}

// Section name styling - now more prominent
.section-name {
    font-size: 1.25rem;
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 0.75rem;
    color: #34495e;
    border-left: 2px solid #f0ad4e;
    padding-left: 0.5rem;
}

// Section instruction styling
.section-instruction-header {
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: #444;
    padding-left: 1.2rem;
}

.section-instructions {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #555;
    padding: 0.5rem 0.75rem;
    background-color: rgba(245, 245, 245, 0.5);
    border-radius: 4px;
    border-left: 1px solid #ddd;
}

.section-container {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    break-inside: avoid;
    page-break-inside: avoid;
    border-bottom: 1px dashed #eee;
}

.section-container:last-child {
    border-bottom: none;
}

.subject-container {
    margin-bottom: 3rem;
    margin-top: 2rem;
    break-before: page; // Modern browsers
    page-break-before: always; // Older browsers 
}

.subject-container:first-child {
    break-before: auto;
    page-break-before: avoid;
    margin-top: 1rem;
}

.section-instruction-container {
    margin: 1rem 0 1.5rem 0;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    background-color: rgba(250, 250, 250, 0.5);
    border-radius: 4px;
}

/* Styles for both screen and print to keep questions together */
.subject-container {
  /* Modern browsers support for page breaks */
  break-before: page;
  margin-top: 3rem;
}

.subject-container:first-child {
  break-before: auto;
  margin-top: 1rem;
}

.question-wrapper {
  break-inside: avoid;
  margin-bottom: 1.5rem;
}

.section-container {
  break-inside: avoid;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
}

.section-name,
.section-instruction-container {
  break-after: avoid;
}

/* Ensure images don't cause overflow */
.q-attach, 
.o-attach {
  max-width: 100%;
  max-height: 280px;
  object-fit: contain;
}

/* Special spacer for forcing breaks when needed */
.page-break-spacer {
  height: 0;
  page-break-after: always;
  margin: 0;
  border: none;
}

/* Ensure option rows stay together */
.option-w {
  break-inside: avoid;
}