.finance-dashboard-container {
    width: 100%;
    height: 100%;
    padding: 2rem 1rem;
    background-color: #E6E8EA;
}

.dashboard-main-section{
    height: 30.787vh;
    min-height:30.787vh;
    display: flex;
    gap: 1.237vw;
}

.finance-card{
    width:28vw;
    height: 13.542vh;
    background: #FFFFFF;
    border-left: 5px solid #076EFF;
    border-radius: 8px;
    padding: 1.625rem 3.125rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2.214vw;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

}

.finance-label {
    font: normal normal 400 1rem/24px InterRegular !important;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #999999;

}
.dashboard-summary-cards {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.cards {
    display: flex;
    gap: 15px;
}
.finance-amount{
    gap:34px;
    width: 114px;
    height: 24px;
    font: normal normal 500 1.125rem/24px InterSemibold !important;
    font-family: 'Inter';
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #000;
    flex: none;
    order: 2;
    flex-grow: 0;

}
.finance-icon{
    width:2.604vw;
    height: 4.63vh;
  
}
.finance-icon-group {
    flex: none;
    order: 0;
    flex-grow: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}


.finance-divider {
    width: 0px;
    height: 100%;  
    border: 1px solid #4E616B;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0;
    align-self: stretch;
}
.quick-actions-container {
    flex: 1; /* Take remaining space */
    min-width: 300px;
    background-color: #fff;
    border-radius: 15px;
    border-left: 5px solid #076EFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding-left: clamp(20px, 2vw, 38px);
    padding-right: clamp(20px, 2vw, 38px); 
    margin-left: auto; 
}
.quick-actions-header{
    font: normal normal 600 1.5rem/24px InterSemibold !important;
    padding-top: 13px;
    padding-bottom: 40px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.25px;
    color: #000000;
}
.quick-action-item{
    width: 104px;
    height: 107px;
    gap: 19px;
    
}
.quickActions-title{
    font: normal normal normal 16px/24px Intermedium !important;
    letter-spacing: 0.5px;
    color: #000000;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

}

.period-selector {
    background: #F8F8FF;
    border-radius: 8px;
    padding: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 2px;
    pointer-events: all;
    position: relative;
    z-index: 1000;
    margin-right: 10px;
}

.period-selector .p-selectbutton {
    display: inline-flex;
    width: 100%;
    border: none;
    background: transparent;
}

.period-selector .p-button {
    background: transparent;
    border: none;
    color: #1E1B39;
    font: normal normal 400 12px/14px InterMedium;
    padding: 10px 10px ;
    border-radius: 12px;
    cursor: pointer;
    min-width: 70px;
    text-align: center;
    pointer-events: all;
    transition: all 0.2s ease;
}

.period-selector .p-button:not(.p-highlight):hover {
    background-color: red;

}

/* Override all PrimeReact button hover states */
.period-selector .p-selectbutton .p-button.p-highlight:hover,
.period-selector .p-selectbutton .p-button:hover,
.period-selector .p-button:enabled:hover,
.period-selector .p-button:not(button):not(a):not(.p-disabled):hover {
    border: none !important;
    text-align: center !important;
    border-radius: 10px !important;
    font: normal normal 400 12px/14px InterMedium !important;
}

/* Ensure base highlight state matches */
.period-selector .p-button.p-highlight {
    background-color: #1E1B39;
    color: #FFFFFF;
    border: none;
    text-align: center;
    border-radius: 10px;
    font: normal normal 400 12px/14px InterMedium;
}

.chart-container{
    position: absolute;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    padding: 14px ;

}
.chart-Title{
    font: normal normal 700 22px/28px InterSemibold !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #1E1B39;
    position: relative;
    margin-bottom:1px;
}
.chart-sub-tilte{
    font: normal normal 400 16px/20px InterRegular !important;
    width: 81px;
    height: 20px;
    color: #9291A5;
    position: relative;
}

.chart-container {
    height: 100%;
    width: 100%;
}

.chart-container .card {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0px !important;
}

.chart-header {
    flex: 0 0 auto;
    padding-bottom: 1rem;
    /* position: relative; */
    /* height: 70px; */
}

.chart-header .finance-icon {
    position: relative;
    z-index: 1001;
    cursor: pointer;
    pointer-events: all;
}

.chart-header svg {
    pointer-events: all;
}

.chart-header .select-button-wrapper {
    position: relative;
    z-index: 1001;
    pointer-events: all;
}

.chart-header .select-button-wrapper svg {
    pointer-events: all;
    cursor: pointer;
}

.chart-container.icon-divider {
    position: absolute;
    width: 100%;
    height: 1px;
    background: #4E616B;
    margin: 0 8px;
    transform: rotate(-90deg);
}

.icon-divider {
    width: 100%;
    height: 1px;
    position: relative;
    margin: 0 0px;
    display: inline-block;
}

.icon-divider::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #4E616B;
    top: 50%;
    left: 50%;
    padding: 0;
    margin: 0;
    transform: translate(-50%, -50%);
}

.chart-body {
    flex: 1;
    min-height: 0;
    position: relative;
    padding-top: 20px; /* Added to give space for header */
}

.chart-body > div {
    position: absolute;
    width: 100%;
    height: 100%;
}

.chart-body .apexcharts-canvas {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: calc(100% - 30px) !important;  /* Adjust for header space */
}

.chart-body .apexcharts-canvas {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.chart-body .apexcharts-canvas .apexcharts-no-data {
    font: normal normal 400 20px/20px Inter !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #999;
}

.select-button-wrapper {
    position: relative;
    z-index: 1000;
    pointer-events: all;
}

.prevent-grid-drag {
    touch-action: none;
    pointer-events: all !important;
    position: relative;
    z-index: 1001;
}

.prevent-grid-drag * {
    pointer-events: all !important;
}

.react-grid-item .prevent-grid-drag {
    pointer-events: all !important;
}

.custom-tooltip {
    padding: 8px;
    background: #1e1e1e;
    color: #ffffff;
    border-radius: 4px;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
}

.filter-icon-container {
    position: fixed;
    right: 20px;
    bottom: 20px;  /* Fixed position at bottom */
    z-index: 1000;
    user-select: none;
}

.filter-icon {
    width: 40px;
    height: 40px;
    background: #076EFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    cursor: grab;
    transition: all 0.2s ease;
}

.filter-icon:hover {
    transform: scale(1.1);
    background: #076EFF;
}

.filter-icon:active {
    cursor: grabbing;
    background: #076EFF;
}

.filter-svg svg {
    width: 24px;
    height: 24px;
}

.filter-svg svg path {
    transition: stroke 0.2s ease;
}

.filter-icon:hover .filter-svg svg path {
    stroke: #076EFF;
}

.filter-dialog {
    position: absolute;
    right: 50px;
    top: 0;
    width: 300px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    padding: 16px;
    z-index: 1001;
}

.filter-dialog h3 {
    margin: 0 0 16px 0;
    font-size: 18px;
    color: #333;
}

.chart-container-flex {
    display: flex;
    width: 100%;
    height: 100%;
    gap: 20px;
}

.chart-section {
    flex: 1;
    min-width: 0;
}

.trend-change i {
    font-size: 12px;
}

.chart-flex-container {
    display: flex;
    width: 100%;
    height: 100%;
}

.chart-area {
    flex: 1;
    min-width: 0;
    padding-right: 20px;
}

.trend-content {
    flex: 1;
    gap: 8px;
}

.trend-percentage {

    font: normal normal 400 14px/16px InterRegular !important;
    width: 40.22px;
    height: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #9291A5;
    text-align: right;
}
.trend-row{
   padding-bottom: 15px;
}
.trend-list {
    width: 240px;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 100%;
    overflow-y: hidden;
}

.trend-item {
    padding: 12px;
    background: white;
    border-radius: 8px;
    margin-bottom: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.05);
}

.trend-circle {

    margin:0px 5px;
    width: 15.47px;
    height: 15px;
    border: 1.5px solid #962DFF;
    border-radius: 12px;

}

.trend-content {
    flex: 1;
    gap: 12px;
}

.trend-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.trend-label {
    font: normal normal 400 12px/14px InterRegular !important;
    width: 59px;
    height: 14px;
    color: #1E1B39;


}

.trend-change {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 11px;
}

.trend-change.increase {
    background-color: #00C853;
    color: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;


}

.trend-change.decrease {
    background-color: #FF3D57;
    color: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}