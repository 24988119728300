.create-notification {
  .attach-label {

  }

}


.sms-template-txt {
  padding: 10px;
  border: 1px solid var(--shadow-90);
  font: normal normal normal 13px/20px Poppins;
  color: var(--shadow-10);
  margin: 2px;
  border-radius: 4px;
  line-height: 50px;

}


.tsting {
  display: inline-block;
}