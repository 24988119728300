.Montserrat24 {
    font: normal normal normal 14px/21px Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    letter-spacing: 0.25px;
    text-align: left;

}

.poppins24 {
    font: normal normal normal 24px/36px Poppins !important;
    letter-spacing: 0.25px;
    text-align: left;
    color: #979797;
    font-weight: 400;
    font-size: 24px;

}

.addButtonTeacher {
    width: 201px;
    height: 56px;
    gap: 0;
    border-radius: 14px;
    background: var(--components-button-primary-background-default, #076EFF);
    box-shadow: 0px 2px 6px -2px #0000000F;
    font: normal normal normal 18px/24px InterSemiBold;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    color: "#FFFFFF";
    flex-direction: row;
    justify-content: center;
    align-items: center;

}


.addColumnButton {
    font: normal normal normal 14px/16px InterSemiBold !important;
    font-weight: 500;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    border-radius: 10px;
    border-style: none;
    border-width: none;
    height: 40px;
}

.teacherDiaryHead {
    font: normal normal normal 24px/24px InterSemiBold !important;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-decoration-line: underline !important;

    color: #000000;


}

.addButtonttt {
    font: normal normal normal 16px/24px InterRegular !important;
    font-weight: 500;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    border-radius: 12px !important;




    background: #076EFF;

    box-shadow: 0px 8px 12px -2px rgba(0, 0, 0, 0.08), 0px 2px 6px -2px rgba(0, 0, 0, 0.06);


}



.addButtonRadius {
    font: normal normal normal 16px/24px InterRegular !important;
    font-weight: 500;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    border-radius: 10px !important;



    border: 1px solid #076EFF;
    background: #076EFF;

    box-shadow: 0px 8px 12px -2px rgba(0, 0, 0, 0.08), 0px 2px 6px -2px rgba(0, 0, 0, 0.06);


}

.outlinedButtonttt {
    font: normal normal normal 16px/24px InterRegular !important;
    font-weight: 500;
    letter-spacing: 0.3px;
    text-align: center;
    border-radius: 10px !important;

    background: transparent;
    /* No solid background */
    color: #076EFF;
    /* Text color should match the primary color */
    border: 1px solid #076EFF;
    /* Border to create the outlined effect */

    box-shadow: 0px 8px 12px -2px rgba(0, 0, 0, 0.08), 0px 2px 6px -2px rgba(0, 0, 0, 0.06);
}

/* Optional: Hover effect for outlined button */
.outlinedButtonttt:hover {
    background: #076EFF;
    /* Solid background on hover */
    color: white;
    /* White text on hover for contrast */
    border-color: #076EFF;
    /* Maintain border color */
}


.groupFormLabelText {
    //styleName: Body Small / Semibold;
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.25px;
    text-align: left;
    color: #000000
}


.add-a-resource-dialog {
    .p-dialog-header {
        //padding: 0px;
        text-align: center;

        .p-dialog-title {
            color: black;
        }
    }

    .add-a-resource {
        .title {
            margin-top: 0px;
            font: normal normal 800 29px/39px Nunito Sans;
            letter-spacing: 0px;
            color: #171725;
            text-align: center;
            padding-top: 10px;
        }

        .section {
            overflow: hidden;
            //margin: 0px 30%;
            width: 260px;
            padding: 30px;
            margin: auto;
            height: 340px;
            background: transparent linear-gradient(180deg, #4A95F9 0%, #1322E9 100%) 0% 0% no-repeat padding-box;
            border-radius: 41px;

            .add-ico {
                padding: 30px;
                margin-top: 40px;
                align-items: center;
                text-align: center;

                span.add-ic {
                    padding: 32px;
                    //padding-bottom: 14px;
                    opacity: 0.9;
                    border-radius: 20px;

                    i {
                        color: white;
                        padding: 6px;
                        padding-right: 7px;
                        border-radius: 50%;
                        //  background-color: var(--theme-65);
                        //opacity: 0.7;
                        font-size: 16px;
                        font-weight: bold;
                    }


                }

                span.add-ic1 {
                    background-color: #4A95F9;
                }

                span.add-ic2 {
                    background-color: #F9834A;
                }

                .add-txt {
                    margin-left: 10px;
                    font: normal normal 600 10px/16px Poppins;
                    letter-spacing: 0px;
                    color: var(--primary-color);
                }
            }

            .section-text {
                padding: 20px;
                text-align: center;
                font: normal normal 600 20px Poppins;
                letter-spacing: 0px;
                color: #FFFFFF;
                text-shadow: 0px 3px 6px #00000029;
            }

        }

        .section-1 {
            background: transparent linear-gradient(180deg, #4A95F9 0%, #1322E9 100%) 0% 0% no-repeat padding-box;
            border-radius: 41px;
        }

        .section-2 {
            background: transparent linear-gradient(180deg, #F9834A 0%, #E94D13 100%) 0% 0% no-repeat padding-box;
            border-radius: 41px;
        }


        .preview-c {

            text-decoration: underline;
            font: normal normal normal 15px/20px Nunito Sans;
            letter-spacing: 0.51px;
            color: #333333;
            opacity: 0.58;

            span {
                cursor: pointer;
            }
        }

        .resources-list {
            padding-left: 5%;
            padding-right: 5%;


            .tbl-title {

                font: normal normal bold 20px/27px Nunito Sans;
                letter-spacing: 0.68px;
                color: #333333;
                text-shadow: 0px 2px 3px #00000029;
            }
        }

        .upload-resource {
            padding-left: 3%;
            padding-right: 3%;

            .title {
                text-align: left;
                font: normal normal 800 18px/24px Nunito Sans;
                letter-spacing: 0px;
                color: #171725;
            }

            .select-file {
                margin-left: 10px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid var(--primary-color);
                border-radius: 7px;
                font: normal normal 600 13px/18px Nunito Sans;
                letter-spacing: 0px;
                color: var(--primary-color);
                padding: 5px 10px;
                margin-top: 20px;
            }

            .up-btn {
                top: 4px;
                margin-top: 24px;
            }


            .third-party {
                margin-top: 30px;
                padding: 20px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 10px;


                .title {
                    font: normal normal 800 18px/24px Nunito Sans;
                    letter-spacing: 0px;
                    color: #171725;
                }
            }


        }
    }


}





.exam-preview {


    .e-label-2 {
        font: normal normal normal 15px/16px InterSemiBold;
        font-weight: 500;
        letter-spacing: 0.4000000059604645px;
        color: #B4B4B4;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 10px;
        line-height: 24px;

    }



    .exam-name {
        font: normal normal normal 15px/16px InterSemiBold;
        font-weight: 500;
        letter-spacing: 0.4000000059604645px;
        color: #E28900;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 10px;
        //margin-top: 5px;
        line-height: 24px;
    }




    .exam-info {
        display: flex;
        justify-content: space-between; // Adjust spacing between columns if needed
        align-items: center; // Aligns items vertically in the center

        .p-col-12 {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px;
        }

        .e-label {
            font: normal normal normal 15px/16px InterSemiBold;
            font-weight: 500;
            letter-spacing: 0.4000000059604645px;
            color: #B4B4B4;
        }

        .e-value {
            font: normal normal normal 15px/16px InterSemiBold;
            font-weight: 500;
            letter-spacing: 0.4000000059604645px;
            color: #E28900;
            text-align: center;
            line-height: 24px;
        }

    }

}


.addsearch {
    font: normal normal normal 16px/24px InterRegular !important;
    font-weight: 500;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    border-radius: 8px !important;



    border: 1px solid #076EFF;
    background: #076EFF;
    margin: 2px;

    box-shadow: 0px 8px 12px -2px rgba(0, 0, 0, 0.08), 0px 2px 6px -2px rgba(0, 0, 0, 0.06);


}

.outlinedCancel {
    font: normal normal normal 16px/24px InterRegular !important;
    font-weight: 500;
    letter-spacing: 0.3px;
    text-align: center;
    border-radius: 8px !important;
    margin: 2px;
    background: transparent;
    /* No solid background */
    color: #076EFF;
    /* Text color should match the primary color */
    border: 1px solid #076EFF;
    /* Border to create the outlined effect */

    box-shadow: 0px 8px 12px -2px rgba(0, 0, 0, 0.08), 0px 2px 6px -2px rgba(0, 0, 0, 0.06);
}

.outlinedButtonttt:hover {
    background: white;
    /* Solid background on hover */
    color: white;
    /* White text on hover for contrast */
    border-color: #076EFF;
    /* Maintain border color */
}

.p-inputtext-style1 {
    font: normal normal normal 14px/24px InterRegular !important;
    font-weight: 500;
    letter-spacing: 0.3px;
    padding: 0.35rem 0.5rem;
    background: var(--theme-100) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--shadow-85);
    border: 0.5px solid var(--theme-85);
    border-radius: 5px;

}

.p-checkbox-label {

    font: normal normal normal 16px/24px InterSemiBold !important;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #1e1e1e;
    color: var(--shadow-10);
    opacity: 0.90;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.avail-q {
    span {
        font: normal normal normal 16px/24px InterSemiBold !important;
        font-weight: 500;
        letter-spacing: 0.3px;
        color: #888888;
    }
}


.success-dialog {
    min-width: 400px;
    overflow: hidden;
    max-width: 400px;

    .img-center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 72px;
    }

    .success-title {
        text-align: center;

        font: normal normal normal 36px/49px InterSemiBold !important;
        font-weight: 500;
        letter-spacing: 0.3px;
      
        color: #7E7E7E;
        margin: 0px;
    }

    .success-msg {
        text-align: center;
        font: normal normal normal 24px/32px InterSemiBold !important;
        font-weight: 500;
        letter-spacing: 0.3px;
        color: #1E1E1E;

        margin: 0px;
        margin-top: 10px;
    }

    .title-s1 {
        text-align: center;
        font: normal normal normal 24px/32px InterSemiBold !important;
        font-weight: 500;
        letter-spacing: 0.3px;
        color: #1E1E1E;
        margin: 0px 0px 5px 0px;
    }

    .desc-s1 {
        text-align: center;
        font: normal normal normal 14px/19px InterSemiBold !important;
        font-weight: 500;
        letter-spacing: 0.3px;
        color: #888888;
        margin: 0px;
    }



}


.addButtonRadiusTransper {
    font: normal normal normal 16px/24px InterRegular !important;
    font-weight: 500;
    letter-spacing: 0.3px;
    text-align: center;
    border-radius: 20px !important;

    border: 1px solid #076EFF;
    background-color: #076EFF;

    box-shadow: 0px 8px 12px -2px rgba(0, 0, 0, 0.08), 0px 2px 6px -2px rgba(0, 0, 0, 0.06);
}


