.add-vehicle-btn{
    width: 169px;
height: 56px;
padding: var(--space-200);
gap: var(--space-0);
border-radius: 15px;

background: var(--components-button-primary-background-default, #076EFF);
box-shadow: 0px 2px 6px -2px #0000000F;

box-shadow: 0px 8px 12px -2px #00000014;
animation-duration: 0ms;
font: normal normal normal normal InterSemiBold;
font-size: 18px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.25px;
text-align: center;

}


.transport-header{
font-family: 'InterSemiBold';
font-style: normal;
font-weight: 500;
font-size: 36px;
line-height: 24px;
text-align: center;
letter-spacing: 0.3px;
color: #000000;
}
.add-vehicle-field-label{
font-family: 'InterSemiBold';
font-style: normal;
font-size: 14px;
font-weight: 500;
line-height: 21px;
letter-spacing: 0.25px;
text-align: left;
width: auto;
height: 21px;
gap: 0px;

color: #000000;
}
.add-vehicle-field-input{
font: normal normal normal normal InterSemiBold;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.5px;
text-align: left;
gap: 0px;

color: #000000;
}
.transport-fee-form-label{
font: normal normal normal 10px/14px InterSemiBold;
font-size: 14px;
font-weight: 500;
line-height: 21px;
letter-spacing: 0.25px;
text-align: left;
width:auto;
color: #000000;
}
.add-new-fee-btn{
width: 260px;
height: 56px;
padding: var(--space-200);
gap: var(--space-0);
border-radius: 15px;
background: var(--components-button-secondary-background-default, #F4F5F6);
font: normal normal normal 10px/14px InterSemiBold;
font-size: 18px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.25px;
text-align: center;
color:#171C1F;
}
.transport-fee-form-heading{
font: normal normal normal 10px/14px InterSemiBold;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.5px;
text-align: left;
color: #000000;
}
.transport-fee-form-para{
font: normal normal normal normal InterSemiBold;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.5px;
text-align: left;
color: #4E616B;
}