:root {
  --dialog-width-lg: 720px; /* Large size */
  --dialog-width-md: 600px; /* Medium size */
  --dialog-width-sm: 320px; /* Small size */
}

             
             .button {
               // font: normal normal normal 22px/33px Poppins; 
               font-family: "Poppins";
               font-size: '15px';
               font-weight: 500;
               line-height: '24px';
               color: "white";
               border-radius: "8px";
               letter-spacing: "0.25px";
               background-color: #076EFF;
               padding: '5px 25px';
               cursor: 'pointer'
             }

             ;

             .border-none {
               border: none !important;
             }

             .no-border {
               border: none !important;
               outline: none !important;
               box-shadow: none !important;
             }

             .formText {
               //styleName: Body Medium/Regular;
               // font: normal normal normal 16px/24px Inter;
               font: normal normal normal 14px/21px Montserrat;
               font-size: 16px;
               font-weight: 400;
               line-height: 24px;
               letter-spacing: 0.5px;
               text-align: left;

             }

             .tabText {
               //styleName: Action Large/Semibold;
               font: normal normal normal 16px/24px InterSemiBold !important;
               font-size: 25px;
               font-weight: 500;
               line-height: 24px;
               letter-spacing: 0.25px;
               color: black !important;


             }

             .doubts-tabs {
               text-align: center;

               .doubts-tab-w {
                 padding: 0px 10px;
               }

             }

             .tabPara {
               font: normal normal normal 10px/14px InterSemiBold;
               font-size: 18px;
               letter-spacing: 0.25px;
               line-height: 24px;
               color: #000000;
               font-weight: 600;
               margin-bottom: 10px;
               padding: 7px;
             }

             .cardMainText {
               font: normal normal normal 10px/14px Montserrat;
               font-size: 24px;
               font-weight: 600;
               line-height: 29.26px;
               letter-spacing: 0.25px;
               text-align: left;

             }

             .cardSize {
               width: 338px;
               height: 191px;
               top: 246px;
               left: 545px;
               gap: 0px;
               

             }

             .cardCheckBox {
               width: 40px;
               height: 40px;
               gap: 0px;
               

             }

             .cardPara {
               font: normal normal normal 10px/14px Poppins;
               font-size: 24px;
               font-weight: 400;
               line-height: 36px;
               letter-spacing: 0.25px;
               text-align: left;
               width: 137px;
               height: 36px;
               top: 347px;
               left: 230px;
               gap: 0px;
               
               color: #979797;
             }

             .confirmDialogHeader {
               //styleName: Heading 4 / Bold;
               font: normal normal normal 10px/14px InterSemiBold;
               font-size: 20px;
               font-weight: 700;
               line-height: 24.2px;
               letter-spacing: 0.15000000596046448px;
               text-align: center;
               color: #000000;

             }

             .confirmDialogSize {
               width: 384px;
               height: 312px;
               gap: 0px;
               

             }

             .termCardDatesSize {
               font: normal normal normal 10px/14px Poppins;
               font-size: 18px;
               font-weight: 600;
               line-height: 27px;
               letter-spacing: 0.25px;
               text-align: left;
               color: #000000 !important;
               //  width: 263px;
               //  height: 27px;

               gap: 0px;
               


             }

             .termCardHeadText {

               font: normal normal normal 10px/14px Montserrat;
               font-size: 24px;
               font-weight: 600;
               line-height: 29.26px;
               letter-spacing: 0.25px;
               text-align: left;
               //  width: 147px;
               //  height: 29px;

             }

             .termCardPara {
               font: normal normal normal 10px/14px Poppins;
               font-size: 16px;
               font-weight: 400;
               line-height: 24px;
               letter-spacing: 0.25px;
               text-align: left;
               //  width: 205px;
               //  height: 24px;

             }

             .confirmDialogPara {
               //styleName: Body Medium/Regular;
               font: normal normal normal 10px/14px InterSemiBold;
               font-size: 16px;
               font-weight: 400;
               line-height: 24px;
               letter-spacing: 0.5px;
               text-align: center;
               color: #4E616B;




             }

             .AddButton {
               width: 100px;
               height: 45px;

               gap: 0;
               border-radius: 16px;

               
               background: var(--components-button-primary-background-default, #076EFF);
               box-shadow: 0px 2px 6px -2px #0000000F;
               //styleName: Action Medium/Semibold;
               font: normal normal normal 10px/14px InterSemiBold;
               font-size: 16px;
               font-weight: 500;
               line-height: 24px;
               letter-spacing: 0.30000001192092896px;
               text-align: center;
               color: "#FFFFFF"



             }



             .confirmDialogAcceptBtn {
               width: 150px;
               height: 48px;

               gap: 0;
               border-radius: 15px;

               
               background: var(--components-button-primary-background-default, #076EFF);
               box-shadow: 0px 2px 6px -2px #0000000F;
               //styleName: Action Medium/Semibold;
               font: normal normal normal 10px/14px InterSemiBold;
               font-size: 16px;
               font-weight: 500;
               line-height: 24px;
               letter-spacing: 0.30000001192092896px;
               text-align: center;
               color: "#FFFFFF"



             }

             .confirmDialogCancelBtn {
               width: 150px;
               height: 48px;

               gap: 0;
               border-radius: 15px;
               
               background: var(--components-button-secondary-background-default, #F4F5F6);

               box-shadow: 0px 2px 6px -2px #0000000F;
               //styleName: Action Medium/Semibold;
               font: normal normal normal 10px/14px InterSemiBold;
               font-size: 16px;
               border: none;
               font-weight: 500;
               line-height: 24px;
               letter-spacing: 0.30000001192092896px;
               text-align: center;
               color: #000000;



             }

             .groupFormInputText {
               //styleName: Body Medium/Regular;
               font: normal normal normal normal InterSemiBold;
               font-size: 16px;
               font-weight: 400;
               line-height: 24px;
               letter-spacing: 0.5px;
               text-align: left;
               border-radius: 12px;
               color: #000000
             }

             .groupFormLabelText {
               //styleName: Body Small / Semibold;
               font: normal normal normal 10px/14px InterSemiBold;
               font-size: 14px;
               font-weight: 500;
               line-height: 21px;
               letter-spacing: 0.25px;
               text-align: left;
               color: #000000
             }

             .branchMasterViewText {
               //styleName: Action Large/Semibold;
               font: normal normal normal 10px/14px InterSemiBold;
               font-size: 18px;
               font-weight: 500;
               line-height: 24px;
               letter-spacing: 0.25px;
               text-align: left;
               color: #000000;

             }

             /* Custom CSS for InputSwitch */
             .custom-inputswitch .p-inputswitch-slider {
               background-color: #007bff !important;
               /* Your desired color */
               border-color: #007bff !important;
             }

             .custom-inputswitch .p-inputswitch-slider:before {
               background-color: white !important;
               /* Optional: the color of the circle */
             }

             .dataTableValuesText {
               //styleName: Body Medium/Semibold;
               font: normal normal normal 10px/14px InterSemiBold;
               font-size: 16px;
               font-weight: 500;
               line-height: 24px;
               letter-spacing: 0.5px;
               text-align: left;
               color: black !important
             }/* Add this CSS to your stylesheet */

.fieldContainer:focus {
  border : 2px solid skyblue !important;
}
.border-calendar {
  border: none !important;
  box-shadow: none !important;
}
.custom-date-Focused:focus{
  border:3px solid #87CEEB !important; /* Light blue border color */
  box-shadow: 0 0 0 0.3rem rgba(135, 206, 235, 0.5) !important; /* Increased shadow with light blue color */
  border-radius: 6px !important;
}
.custom-date-group {
  border-radius: 6px;
  overflow: hidden;  /* Ensure that the border radius is applied to the whole container */
  border: 1px solid #ced4da; /* Default border color */
  background: white; /* Ensure the background is white */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}


// .custom-desc-group {
//   border-radius: 6px;
//   overflow: hidden;  /* Ensure that the border radius is applied to the whole container */
//   border: 1px solid #ced4da; /* Default border color */
//   background: white; /* Ensure the background is white */
//   transition: border-color 0.3s ease, box-shadow 0.3s ease;
// }
.no-border:focus {
  border: none !important;
  box-shadow: none !important;
}

.custom-desc-focused:focus {
  border:3px solid #8ec7f3 !important; /* Light blue border color */
  box-shadow: 0 0 0 0.3rem #cce4f6 !important; /* Increased shadow with light blue color */
  border-radius: 6px ;
}


/* styles.css */


@media (max-width: 1199px) {
  .dialog-box {
    max-width: 900px;
  }
  .desc{
    width:850px
  }
}

@media (max-width: 767px) {
  .dialog-box {
    max-width: 600px;
  }
  .desc{
    width:500px
  }
}

@media (max-width: 480px) {
  .dialog-box {
    max-width: 90%;
  }
  .desc{
    width:343px
  }
}

.grid .col {
  padding: 1rem;
}

@media (max-width: 767px) {
  .grid .col {
    width: 100%;
    padding: 0.5rem;
  }
}

@media (max-width: 480px) {
  .grid .col {
    width: 100%;
    padding: 0.5rem;
  }
}
