.Montserrat24 {
    font: normal normal normal 14px/21px Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    letter-spacing: 0.25px;
    text-align: left;

}

.poppins24 {
    font: normal normal normal 24px/36px Poppins;
    letter-spacing: 0.25px;
    text-align: left;
    color: #979797;
    font-weight: 400;
    font-size: 24px;

}

.addButton {
    font: normal normal normal 16px/24px Inter;
    font-weight: 500;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
}

.repText {
    font: normal normal normal 12px/16px InterSemiBold;
    font-weight: 500;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
}

.teacherDiaryHead {
    font: normal normal normal 24px/24px InterSemiBold;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-decoration-line: underline;
    color: #000000;
}

.time-panel {
    font-size: 1rem;
}

.time-panel .p-hour-picker, 
.time-panel .p-minute-picker,
.time-panel .p-ampm-picker {
    font-size: 1.2rem;
    padding: 0.5rem;
}

.p-calendar.p-calendar-timeonly .p-inputtext {
    padding-left: 2.5rem;
    height: 48px;
}

.p-input-icon-left > i, 
.p-input-icon-left > svg {
    left: 0.75rem;
    color: #6c757d;
}

.p-calendar .p-inputtext::placeholder {
    color: #a0a0a0;
}

.event-dialog {
    .p-dialog-content {
        padding: 1rem;
        overflow-y: auto;
    }
    
    .p-dialog-header {
        padding: 1.25rem 1.5rem 0.5rem;
        border-bottom: 1px solid #dee2e6;
    }
    
    .p-dialog-footer {
        border-top: 1px solid #dee2e6;
        padding: 1rem 1.5rem;
    }
}

// Fix for modal centering
// .p-dialog-mask.p-component-overlay {
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }

.flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.justify-content-between {
    justify-content: space-between;
}

.flex .labelToggle {
    margin-bottom: 0 !important;
    margin-left: 8px;
    font: normal normal normal 14px / 21px InterSemiBold !important;
}

// Add this to style Sunday cells

.sunday-cell {
    background-color: #F8F9FA !important;
    color: #E6E8EA !important;
}

// Optional: Style Sunday headers differently
.fc-day-sun .fc-col-header-cell-cushion {
    color: #E6E8EA;
}

// Add these styles for Sunday cells and Holiday events

/* Sunday cell styling */
.fc .fc-day.fc-day-sun,
.fc .fc-daygrid-day.fc-day-sun,
.sunday-cell {
  background-color: #F8F9FA !important;
}

/* Sunday header styling */
.fc .fc-col-header-cell.fc-day-sun {
  background-color: #F8F9FA !important;
}

/* Holiday events - background style */
.fc .fc-bg-event {
  /* Ensure holiday background events are solid and visible */
  opacity: 0.9 !important;
  color: white !important;
}

/* Target specifically holiday background events */
.fc .fc-bg-event.holiday-event {
  background-color: #268900 !important;
}

/* Style holiday event text */
.fc .fc-bg-event .fc-event-title {
  font-weight: bold !important;
  font-size: 1.2em !important;
  margin: 4px 0 !important;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5) !important;
}

/* Add these styles to fix event colors */

/* Make sure events have proper colors */
.fc-event {
  border-radius: 3px !important;
}

/* Specific event type colors */
.event-type-ae {
  background-color: #0077B6 !important;
  border-color: #0077B6 !important;
}

.event-type-se {
  background-color: #FAB710 !important;
  border-color: #FAB710 !important;
}

.event-type-ce {
  background-color: #A020F0 !important;
  border-color: #A020F0 !important;
}

.event-type-me {
  background-color: #E6E8EA !important;
  border-color: #E6E8EA !important;
  color: #333333 !important;
}

.event-type-ee {
  background-color: #BF0031 !important;
  border-color: #BF0031 !important;
}

.event-type-he {
  background-color: #268900 !important;
  border-color: #268900 !important;
}

/* Ensure recurring events with times have proper styling */
.recurring-event.timed-event {
  opacity: 0.9 !important;
}

/* Improve text visibility */
.fc-event-title, .fc-event-time {
  font-weight: bold !important;
  padding: 1px 3px !important;
}

/* Fix for event colors */

/* Base event styling */
.fc-event {
  border-width: 1px !important;
}

/* Type-specific styling with high specificity */
.fc-event.event-type-ae,
.fc-daygrid-event.event-type-ae,
.fc-timegrid-event.event-type-ae,
.fc-list-event.event-type-ae,
.fc-event-time.event-type-ae,
.fc-event-title.event-type-ae {
  background-color: #0077B6 !important;
  border-color: #0077B6 !important;
  color: white !important;
}

.fc-event.event-type-se,
.fc-daygrid-event.event-type-se,
.fc-timegrid-event.event-type-se,
.fc-list-event.event-type-se,
.fc-event-time.event-type-se,
.fc-event-title.event-type-se {
  background-color: #FAB710 !important;
  border-color: #FAB710 !important;
  color: white !important;
}

.fc-event.event-type-ce,
.fc-daygrid-event.event-type-ce,
.fc-timegrid-event.event-type-ce,
.fc-list-event.event-type-ce,
.fc-event-time.event-type-ce,
.fc-event-title.event-type-ce {
  background-color: #A020F0 !important;
  border-color: #A020F0 !important;
  color: white !important;
}

.fc-event.event-type-me,
.fc-daygrid-event.event-type-me,
.fc-timegrid-event.event-type-me,
.fc-list-event.event-type-me,
.fc-event-time.event-type-me,
.fc-event-title.event-type-me {
  background-color: #E6E8EA !important;
  border-color: #E6E8EA !important;
  color: #333333 !important;
}

.fc-event.event-type-ee,
.fc-daygrid-event.event-type-ee,
.fc-timegrid-event.event-type-ee,
.fc-list-event.event-type-ee,
.fc-event-time.event-type-ee,
.fc-event-title.event-type-ee {
  background-color: #BF0031 !important;
  border-color: #BF0031 !important;
  color: white !important;
}

.fc-event.event-type-he,
.fc-daygrid-event.event-type-he,
.fc-timegrid-event.event-type-he,
.fc-list-event.event-type-he,
.fc-event-time.event-type-he,
.fc-event-title.event-type-he {
  background-color: #268900 !important;
  border-color: #268900 !important;
  color: white !important;
}

/* Fix for recurring timed events */
.recurring-event.timed-event .fc-event-main-frame,
.recurring-event.timed-event .fc-event-main,
.recurring-event.timed-event .fc-event-time,
.recurring-event.timed-event .fc-event-title {
  color: inherit !important;
  background-color: inherit !important;
}

/* Ensure text colors are applied to inner elements */
.fc-event-title,
.fc-event-time {
  color: inherit !important;
  background-color: inherit !important;
}