.Montserrat24 {
    font: normal normal normal 14px/21px Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    letter-spacing: 0.25px;
    text-align: left;

}

.poppins24 {
    font: normal normal normal 24px/36px Poppins !important;
    letter-spacing: 0.25px;
    text-align: left;
    color: #979797;
    font-weight: 400;
    font-size: 24px;

}

.addButton {
    font: normal normal normal 16px/24px InterSemiBold !important;
    font-weight: 500;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    border-radius: 12px;
    border-style:none;
    border-width: none;
}


.total{
font: normal normal normal 18px/27px Poppins !important;
font-weight: 600;
font-size: 18px;
line-height: 27px;
letter-spacing: 0.25px;
color: #000000;
}

.title{
font: normal normal normal 24px/29px Montserrat !important;
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 29px;
letter-spacing: 0.25px;
color: #000000;


}








