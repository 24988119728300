.side-headder{
    font-family:"InterRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    color: #000000;
}

.contact-no{
    width: 531px;
    height: 56px;
    font-family: 'InterRegular';
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0.5px;
    background: #D9D9D9;
    border-radius: 8px;

}
.sub-title{
    font-family: 'InterRegular';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #4E616B;
    padding: 0px;
    margin: 0px;
}

.formEditEnquiryText{
    font-family: 'InterRegular';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
}

.formHeader{
    font-family: 'InterRegular';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: #000000;
}
.rejectBtn{
    
    padding: 12px;
    
    width: 120px;
    height: 48px;
    border: none;
    background: #BF0031;
    border-radius: 8px;
    box-shadow: 0px 2px 6px -2px #0000000F;
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.30000001192092896px;
    text-align: center;

}


.enrolledData {


font-family: 'InterRegular';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
text-align: center;
letter-spacing: 0.15px;
color: #4E616B;

}
.enrolledDataHeaders {


    font-family: 'InterRegular';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #4E616B;
    
    }

 .p-splitbutton.p-button-info.p-button-text > .p-button {
    background-color: transparent;
    color: #fff;
    border-color: transparent;
}
.p-splitbutton.p-button-info.p-button-text > .p-button:hover {
    background-color: transparent !important;
    color: #fff !important;
    border-color: transparent !important;
}
 