.attach-preview-d {
    position: relative;
    display: inline-block;

    img {
        height: auto;
    }

    .attach-remove {
        position: absolute;
        top: 8px;
        // right: 16px;
        font-size: 18px;
        cursor: pointer;
    }
}

.latex-input {
    line-height: 24px;
}

.vidoes-linked {
    margin-top: 5px;

    .p-datatable {
        border: 1px solid rgba(0, 0, 0, 0.2)
    }
}



.question-in-tbl {


    .question-label {
        margin-top: 5px;
        margin-bottom: 5px;
        font: normal normal normal 14px/19px InterSemiBold;
        font-weight: 600;
      
        letter-spacing: 0px;
        color: #333333;

    }

    .key-value {
        margin-left: 10px;
    }

    .q-option-keys {

        font: normal normal normal 14px/19px InterSemiBold;
        font-weight: 600;
        letter-spacing: 0px;
        color: #333333;
        margin: 0px;
        margin-right: 10px;
    }


    .option-w {
        width: 50%;
        float: left;

        padding: 5px;
        .option {
            margin: 4px 15px 4px 50px;
            font-size: 18px;
        }
    }

    .q-attach {
        margin-left: 100px;
        padding: 10px;
        max-width: 20%;
    }

    .o-attach {
        margin-left: 60px;
        padding: 10px;
        // max-height: 200px;
        max-width: 40%;
    }

}


.question-preview {

   
    font: normal normal normal 14px/16px InterSemiBold;
    font-weight: 800;
}

.add-a-resource {
    .title {
        margin-top: 0px;
        font: normal normal normal 29px/39px InterSemiBold;
        font-weight: 800;
        letter-spacing: 0px;
        color: #171725;
        text-align: center;
        padding-top: 10px;
    }

    .section {
        overflow: hidden;
        //margin: 0px 30%;
        width: 260px;
        padding: 30px;
        margin: auto;
        height: 340px;
        background: transparent linear-gradient(180deg, #4A95F9 0%, #1322E9 100%) 0% 0% no-repeat padding-box;
        border-radius: 41px;

        .add-ico {
            padding: 30px;
            margin-top: 40px;
            align-items: center;
            text-align: center;

            span.add-ic {
                padding: 32px;
                //padding-bottom: 14px;
                opacity: 0.9;
                border-radius: 20px;

                i {
                    color: white;
                    padding: 6px;
                    padding-right: 7px;
                    border-radius: 50%;
                    //  background-color: var(--theme-65);
                    //opacity: 0.7;
                    font-size: 16px;
                    font-weight: bold;
                }


            }

            span.add-ic1 {
                background-color: #4A95F9;
            }

            span.add-ic2 {
                background-color: #F9834A;
            }

            .add-txt {
                margin-left: 10px;
                font: normal normal normal 10px/16px InterSemiBold;
                font-weight: 600;
                letter-spacing: 0px;
                color: var(--primary-color);
            }
        }

        .section-text {
            padding: 20px;
            text-align: center;
            font: normal normal normal 10px/20px InterSemiBold;
            font-weight: 600;
            letter-spacing: 0px;
            color: #FFFFFF;
            text-shadow: 0px 3px 6px #00000029;
        }

    }

    .section-1 {
        background: transparent linear-gradient(180deg, #4A95F9 0%, #1322E9 100%) 0% 0% no-repeat padding-box;
        border-radius: 41px;
    }

    .section-2 {
        background: transparent linear-gradient(180deg, #F9834A 0%, #E94D13 100%) 0% 0% no-repeat padding-box;
        border-radius: 41px;
    }

    .preview-qo {
        padding: 10px 20px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        //border-radius: 6px;
        min-height: 90px;
    
    
    }
    .preview-c {

        text-decoration: underline;
        font: normal normal normal 15px/20px InterSemiBold;
        font-weight: 600;
        letter-spacing: 0.51px;
        color: #333333;
        opacity: 0.58;
        font-size: medium;

        span {
            cursor: pointer;
        }
    }

    .resources-list {
        padding-left: 5%;
        padding-right: 5%;


        .tbl-title {
            font: normal normal normal 20px/27px InterSemiBold;
            font-weight: 600;
            letter-spacing: 0.68px;
            color: #333333;
            text-shadow: 0px 2px 3px #00000029;
        }
    }

    .upload-resource {
        padding-left: 3%;
        padding-right: 3%;

        .title {
            text-align: left;

            font: normal normal normal 18px/24px InterSemiBold;
            font-weight: 800;
            letter-spacing: 0px;
            color: #171725;
        }

        .select-file {
            margin-left: 10px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid var(--primary-color);
            border-radius: 7px;

            font: normal normal normal 13px/18px InterSemiBold;
            font-weight: 600;
            letter-spacing: 0px;
            color: var(--primary-color);
            padding: 5px 10px;
            margin-top: 20px;
        }

        .up-btn {
            top: 4px;
            margin-top: 24px;
        }


        .third-party {
            margin-top: 30px;
            padding: 20px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;


            .title {
                font: normal normal normal 18px/24px InterSemiBold;
                font-weight: 800;
                
                letter-spacing: 0px;
                color: #171725;
            }
        }


    }
}