.Montserrat24 {
    font: normal normal normal 14px/21px Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    letter-spacing: 0.25px;
    text-align: left;

}

.poppins24 {
    font: normal normal normal 24px/36px Poppins !important;
    letter-spacing: 0.25px;
    text-align: left;
    color: #979797;

}
.search-input {
    border-radius: 6px;
    overflow: hidden;  /* Ensure that the border radius is applied to the whole container */
    border: 1px solid #ced4da; /* Default border color */
    background: white; /* Ensure the background is white */
    // transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.addButton {
    font: normal normal normal 16px/24px Inter;
    font-weight: 500;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
}

.sideHeading {
    font: normal normal normal 16px/24px InterSemiBold;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    color: black;
}

.topicName {
    font: normal normal normal 16px/24px InterRegular !important;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;

}

.interSemibold {
    font: normal normal normal 18px/26px InterSemiBold !important;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.5px;
    text-align: left;
}

.interbold {
    font: normal normal normal 20px/24px InterBold !important;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
}

.selectedResource {
    padding: '0px 24px 0px 24px';
    background-color: '#F8F9FA';
}

/* CSS Class Names */
.body-medium {
    font: normal normal normal 16px/24px InterMedium !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #000; 
   
}

.body-regular {
    font-family: Inter;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #000; 
   
}


/* CSS Class Name */
.body-small-semibold {
    font: normal normal normal 14px/21px InterSemiBold !important;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.25px;
    text-align: left;
    /* Specify your color block */
    color: #000000; /* Example color */
    /* Additional styles as needed */
}


.body-small-semibold-color {
    font: normal normal normal 14px/21px InterSemiBold !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.25px;
    text-align: left;
    /* Specify your color block */
    color: #4E616B; /* Example color */
    /* Additional styles as needed */
}

.body-medium-semibold {
    font: normal normal normal 14px/21px InterSemiBold !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    /* Additional styles as needed */
    width: 3rem; /* Example width */
}

.body-medium-color {
    font: normal normal normal 14px/21px InterMedium !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #667A85; 
   
}

.input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.input-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* Ensures the icon doesn't block input field interactions */
    color: #667A85; /* Example color */
}

.input-field {
    width: 100%;
    padding-right: 30px; /* Add padding to avoid text overlapping with the icon */
}
