.profile-img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
}

.profile-name {
    font: normal normal normal 15px/18px Roboto;
    letter-spacing: 0px;
    color: var(--primary-color);
    margin: 0px;
}

.profile-name2 {
    font: normal normal normal 15px/18px Roboto;
    letter-spacing: 0px;
    margin: 0px;
}

.ma-box {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 8px;
    padding: 5px 14px;
}

.discussion-board {
    overflow: hidden;
    word-wrap: break-word;
    // background-color: #f1f9f9;
    font-family: Nunito Sans;
    //width: 90%;
    margin: auto;
    padding: 16px;
    min-height: calc(100vh - 180px);

    .disc-tabs-header {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 6px;

        .disc-header {
            font: normal normal bold 18px/24px Roboto;
            letter-spacing: 0px;
            color: #6a7284;
            margin-bottom: 0px;
            padding: 0px 30px;
            margin-top: 8px;
        }

        .discussion-tabs {
            //  padding-top: 15px;
            //padding-right: 15px;
            float: right;

            li {
                font: normal normal normal 18px/22px Roboto;
                letter-spacing: 0px;
                color: #b2b2b2;
                width: 100%;
                display: inline;
                padding: 14px 0px;
                margin: 20px 20px;
            }

            .tab-active-a {
                color: var(--primary-color);
                border-bottom: 3px solid var(--primary-color);
                font-weight: bold;
            }
        }
    }

    .posts-container {
        margin-top: 16px;

        .posts-grid {
            padding: 0px 20px;
        }
    }
}

.create-desc {
    margin-top: 8px;
    padding: 14px 20px 1px 20px;

    .profile-img {
        width: 28px;
        height: 28px;
        border-radius: 50%;
    }

    .hello-txt {
        text-align: left;
        font: normal normal normal 10px/12px Roboto;
        letter-spacing: 0px;
        margin: 0px;
        color: #CECECE;
    }

    .profile-name {
        font: normal normal normal 15px/18px Roboto;
        letter-spacing: 0px;
        color: var(--primary-color);
        margin: 0px;
    }

    .start-desc {
        margin-top: 10px;
        margin-left: 40px;
        //  padding: 10px;
        font: normal normal normal 18px/24px ;
        font-weight: 500;
        letter-spacing: 0px;
        color: #9a9898;

        //   border: none;
    }
}

.disc-comment {
    margin-left: 50px;
    margin-bottom: 10px;

    .answer-box {
        padding: 5px 5px 0px 5px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0.5px 1px #00000029;
        border: 0.20000000298023224px solid #cccccc;
        border-radius: 8px;
        overflow: hidden;

        .answer-txtarea {
            border: none;
            overflow: hidden;
            width: 100%;
            resize: none;
            font: normal normal normal 12px/14px Roboto;
            letter-spacing: 0px;
            color: #343333;
        }

        .post-txt {
            font: normal normal 600 14px/21px Poppins;
            letter-spacing: 0px;
            color: #343333;
            cursor: pointer;
            margin-left: 10px;
        }
    }
}

.disc-answer {
    margin-left: 10px;
    margin-bottom: 10px;

    .answer-box {
        padding: 5px 5px 0px 5px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0.5px 1px #00000029;
        border: 0.20000000298023224px solid #cccccc;
        border-radius: 8px;
        overflow: hidden;

        .answer-txtarea {
            border: none;
            overflow: hidden;
            width: 100%;
            resize: none;
            font: normal normal normal 12px/14px Roboto;
            letter-spacing: 0px;
            color: #343333;
        }

        .post-txt {
            font: normal normal 600 14px/21px Poppins;
            letter-spacing: 0px;
            color: #b1b1b1;
            cursor: pointer;
            margin-left: 10px;
        }
    }




}

.discussion-item {
    // padding: 0px 20px;

    .answer-c {
        background: #f5f5f5 0% 0% no-repeat padding-box;
        box-shadow: 0px 0.5px 2px #00000029;
        border-radius: 0px 6px 6px 6px;
        padding: 8px 12px;
    }

    .correct-ans {
        background: #11d532 0% 0% no-repeat padding-box;
        border-radius: 3px;
        color: white;
        font: normal normal 500 10px/13px Roboto;

        letter-spacing: 0px;
        font-size: 13px;
        color: #ffffff;
        float: right;
        padding: 3px 6px;
    }

    .answer-txt {
        margin-top: 8px;
        font: normal normal normal 12px/14px Roboto;
        letter-spacing: 0px;
        color: #3a3939;
    }

    .li-img-icon {
        height: 16px;
        width: 16px;
        margin-top: -3px;
        margin-right: 4px;
    }

    .discussion {
        margin-top: 18px;
        padding: 14px 20px 10px 20px;

        .menu-icon {
            height: 20px;
            float: right;
        }

        .discussion-menu {
            margin-top: 0px;
            height: 20px;
            float: right;
            padding: 0px;
            margin-bottom: 0px;

            img {
                height: 20px;
            }

            li {
                font: normal normal normal 10px/14px ;
                letter-spacing: 0px;
                color: #b2b2b2;
                width: 100%;
                display: inline;
                padding: 3px 10px;
                // padding-top: 20px;
            }
        }

        .question {
            font: normal normal bold 15px/20px ;
            letter-spacing: 0px;
            color: #3a3939;
            margin-top: 10px;
            overflow: hidden;
            word-wrap: break-word;
        }

        .created-at {
            font: normal normal normal 9px/11px Roboto;
            letter-spacing: 0px;
            color: #b1b1b1;
            margin-bottom: 2px;
            margin-top: 1px;
        }

        .subject-name {
            span {
                color: #b1b1b1;
            }

            font: normal normal normal 9px/11px ;
            letter-spacing: 0px;
            color: #777373;
            margin-bottom: 2px;
        }
    }

    .desc-img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
    }

    .desc-name {
        float: left;
    }

    .discussion-actions {
        //padding-top: 15px;
        // padding-right: 15px;
        //  float: right;
        padding: 0px;
        margin-bottom: 0px;

        li {
            font: normal normal normal 10px/14px Roboto;
            letter-spacing: 0px;
            color: #b2b2b2;
            width: 100%;
            display: inline;
            padding: 3px 10px;

            // padding-top: 20px;
        }
    }

    .favourite-icon {
        margin-top: 6px;
        float: right;
        color:#b2b2b2;
    }
}

.descr-search-bar {
    margin: 0px 6px;
    padding: 0px 20px;

    .search-input-w {
        margin: 4px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0.5px 1px #00000029;
        border: 0.10000000149011612px solid #cccccc;
        border-radius: 7px;
        overflow: hidden;

        .search-icon {
            height: 20px;
            width: 20px;
            margin-top: 10px;
        }

        .search-input {
            width: 100%;

            padding: 8px;
            border: none;
        }
    }

    .filter-icon {
        position: relative;
        top: 4px;
    }
}

.answers-list {
    // max-height: 300px;
    // overflow-y: auto;
    padding-bottom: 8px;
}

.answers-filter-txt {
    margin: 5px 10px;
    font: normal normal normal 14px/17px Roboto;
    letter-spacing: 0px;
    color: #373737;
    font-weight: 500;

    .answers-filter {
        font: normal normal normal 10px/13px Roboto;
        letter-spacing: 0px;
        color: #7b7b7b;
        margin-top: 3px;

        .top-down {
            margin-left: 5px;
            width: 4px;
        }
    }
}

.comment-hr {
    margin: 8px;
    //color: #E6E6E6;
    border: 0.1px solid #E6E6E6;
}

.sort-filter {
    padding: 16px;
    min-width: 300px;
    z-index: 24;

    font: normal normal normal 16px/19px Roboto;
    letter-spacing: -0.38px;
    color: #929292;




    .sort-items {
        font: normal normal normal 16px/19px Roboto;
        letter-spacing: -0.38px;
        color: #929292;
        //padding: 4px;
    }
}

.report-dialog {
    padding: 10px;

    .report-title {
        font: normal normal normal 20px/24px Roboto;
        letter-spacing: -0.48px;
        color: #1d1d1d;
    }

    .p-field-radiobutton {
        margin-bottom: 0px;
    }

    .report-textarea {
        width: 80%;
        border-radius: 8px;
        padding: 6px;
        padding-right: 20px;
        box-shadow: 0px 0.5px 2px #00000029;
        border: 1px solid #d6d6d6;
        margin-left: 30px;
        font: normal normal normal 12px/14px Roboto;
        resize: none;
    }

}

.leader-board-c {
    padding: 10px;

    .leader-board {
        display: flow-root;

        .leader-tabs {
            padding-top: 15px;
            padding-right: 15px;
            float: right;

            li {
                font: normal normal normal 18px/22px Roboto;
                letter-spacing: 0px;
                color: #b2b2b2;
                width: 100%;
                display: inline;
                margin: 10px 30px;
            }

            .tab-active-a {
                color: var(--primary-color);
                border-bottom: 3px solid var(--primary-color);
            }
        }
    }

    .rank-section {
        top: 40px;
        position: relative;

        .rank-lable {
            font: normal normal normal 14px/17px Roboto;
            letter-spacing: 0px;
            color: #a4a4a4;
            margin-bottom: 0px;
        }

        .rank-value {
            font: normal normal bold 80px/106px Roboto;
            letter-spacing: 0px;
            color: #323da1;
        }
    }

    .desc-value {
        font: normal normal bold 28px/37px Roboto;
        letter-spacing: 0px;
        color: #323da1;
        margin: 0px;
    }

    .desc-lable {
        font: normal normal normal 12px/14px Roboto;
        letter-spacing: 0px;
        color: #a4a4a4;
    }
}

.rank-item-w {
    padding: 10px 10px 0px 10px;

    .rank-item {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 3px #0000001a;
        border-radius: 4px;
    }

    .rank-img {
        //width: 100%;
        width: 51px;
    }

    .rank-name {
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #142291;
        font-weight: 600;
        top: 2px;
        position: relative;
    }

    .rank-value {
        margin: 0px;
        //margin-top: -20px;

        span:nth-child(1) {
            font: normal normal normal 8px/11px Nunito Sans;
            letter-spacing: 0px;
            color: #a4a4a4;
            position: relative;
            top: -10px;
        }

        span:nth-child(2) {
            font: normal normal bold 38px/51px Nunito Sans;
            letter-spacing: 0px;
            color: #323da1;
        }
    }
}

.notifications-c {
    padding: 0px;
    overflow: hidden;

    .notifi-header {
        background-color: var(--theme-95);
        font: normal normal normal 16px/21px Roboto;
        letter-spacing: 0px;
        color: var(--primary-color);
        // padding: 10px;
        padding: 10px 0px 0px 10px;

        .notifi-h-txt {
            margin: 0px;
        }

        .notifi-img {
            width: 24px;
        }
    }

    .notifi-c {
        padding: 0px 10px;

        .txt {
            font: normal normal normal 12px/14px Roboto;
            letter-spacing: 0px;
            color: #515151;
            margin: 0px;
            margin-top: 6px;
        }
    }
}

.no-comments {
    margin: 0px 50px;
    font-weight: 600;

    font-size: 12px;
}

.no-replies {
    margin: 0px 10px;
    font-weight: 600;

    font-size: 12px;
}

.leaderboard-img {
    //width: 190px;
    height: 190px;
    text-align: center;
    margin: auto;
    width: 50%;
    display: block;
}

.comment-attachments {
    padding: 0px;
    margin-bottom: 0px;
    margin-top: 5px;

    li {
        font: normal normal normal 14px/16px Roboto;
        letter-spacing: 0px;
        color: #b2b2b2;
        width: 100%;
        display: inline;
        padding: 3px 4px;

        .li-img-icon {
            height: 16px;
            width: 16px;
            margin-top: -3px;
            margin-right: 4px;
        }
    }
}

.create-discussion-w {}

.create-discussion {
    //padding: 5px;
    //  min-width: 1400px;
    //  min-height: calc(100vh / 2 + 100px);
    // background-color: var(--body-background);
    width: 100%;
    padding: 10px;

    .title {
        // margin: 0px;
        // padding: 16px;
        font: normal normal bold 23px/31px Nunito Sans;
        letter-spacing: -0.55px;
        color: var(--primary-color);
    }

    .select-subject {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 4px #00000029;
        border: 0.5px solid var(--theme-85);
        border-radius: 5px;
        padding: 4px 8px;
        font: normal normal normal 14px/21px Poppins;
        letter-spacing: 0px;
        color: #545454;
        margin: 0px !important;

        .down-svg {
            margin-left: 5px;
            width: 8px;
        }
    }

    .question-of {
        margin-left: 10px;
    }

    // .create-disc-body {
    //      padding: 0px 30px;

    .discussion-scoll-area {
        min-height: 250px;
        max-height: 400px;
        overflow-y: auto;

        padding: 10px;
        height: 100%;
        margin: 20px 10px 0px 10px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 4px #00000029;
        border: 0.5px solid var(--theme-85);
        border-radius: 5px;
        //padding: 10px;

        .discussion-txtarea {
            border: none;
            overflow: hidden;
            width: 100%;
            box-sizing: border-box;
            border: none;
            border-radius: 3px;
            //resize: none;
            font-size: 20px;
            line-height: 24px;
            resize: none;
            font: normal normal normal 16px/19px Roboto;
            letter-spacing: 0px;
            color: #292929;
            //overflow: auto;
            // height: auto;
            //padding: 8px;
            // box-shadow: 0px 4px 10px -8px black;
        }
    }

    // }


    .create-disc-head {

        .hello-txt {
            font: normal normal normal 8px/10px Roboto;
            letter-spacing: 0px;
            color: #CECECE;
            margin: 0px;
        }


        .profile-name {
            font: normal normal normal 15px/18px Roboto;
            letter-spacing: 0px;
            color: var(--primary-color);
            margin: 0px;
        }
    }
}

.create-btn {
    position: absolute !important;
    right: 0px !important;
    float: right;
}

.create-err {
    margin: 0px;
    padding: 5px;
    color: red;
    font-size: 14px;
    margin-left: 14px;
}

.sort-report-icon {
    width: 16px;
    margin-right: 8px;
}

.text-icon {
    width: 17px;
    margin-right: 5px;
}

.warning-icon {
    width: 30%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.warning-title {
    text-align: center;
    font: normal normal bold 18px/24px Nunito Sans;
    letter-spacing: 0px;
    color: #292929;
}

.warning-txt {
    text-align: center;
    font: normal normal 600 14px/19px Nunito Sans;
    letter-spacing: 0px;
    color: #9f9f9f;
}

.teacher-label {
    margin-bottom: 0px;
    margin-top: -3px;

    span {
        font: normal normal medium 8px/10px Roboto;
        font-size: 8px;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 0.95;
        background: #000000 0% 0% no-repeat padding-box;
        border-radius: 3px;
        opacity: 0.6;
        padding: 2px 4px;
    }
}

.subjects-s {
    padding: 8px;
    min-width: 200px;
    z-index: 24;
}

.grade-select {
    padding: 10px;
}

// body {
//     background-color: red;
// }

.create-dailog-w {
    background: #f1f9f9 0% 0% no-repeat padding-box;
    border-radius: 8px;
}

.ma-attachment {
    background: var(--primary-color) 0 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 3px #00000029;
    border: 0.5px solid var(--primary-color);
    width: 100%;
    overflow: hidden;
    padding: 6px;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;

    .ma-attach-icon {
        width: 14px;
    }

    p {
        margin: 0px;
        padding: 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        font: normal normal 600 11px/15px Nunito Sans;
        letter-spacing: -0.26px;
        color: #494848;
        opacity: 1;
    }
}

.ma-attach-wrapper {
    position: relative;
    text-align: center;
    //color: white;
    padding: 10px;
    width: 60%;

    .ma-top-right {
        position: absolute;
        top: 24px;
        right: 24px;
        cursor: pointer;
    }

    .ma-attch-image {
        //width: 100%;
        max-height: 300px;
    }

    .ma-attch-video {
        width: 100%;
    }

    .ma-attch-audio {
        width: 80%;
    }
}

.view-image {
    padding: 10px;

    img {
        width: 30%;
    }
}

.view-video {
    padding: 10px;

    video {
        width: 30%;
    }
}

.error-bord {
    border: 1px solid red !important;
}

.filter-s {
    width: 20% !important;
}

.load-w {
    display: flow-root;
    margin-bottom: 8px;

    .load-more {
        //  color: var(--shadow-50);
        color: var(--primary-color);
        float: right;
        font-size: 13px;
        cursor: pointer;

        i {

            position: relative;
            top: 3px;
        }
    }
}



.delete-icn {
    margin-left: -5px;
    opacity: 0.6;

}








//////////////@at-root

.mgrpdf-navigation.mgrpdf-navigation__controls.mgrpdf-navigation__controls--wrapper {
    position: absolute !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
    left: 0;
    right: 0;
    bottom: 0;
}


.mgrpdf-navigation {
    background-color: #fff !important;
    color: black !important;
    font-weight: 400 !important;
}



.popup-img {

    width: 100%;
    //height: 100%;

}


.ma-danger {
    color: red;
    font-size: 16px;
    margin-left: 3px;
}

.assigned-grades {
    //padding: 20px;
    max-height: 300px;
    overflow: auto;
}


.create-discussion-dilog {

    width: 60%;

    .p-dialog-header {
        padding: 0px;
    }


    .p-dialog-content {
        padding: 0px;
    }



}

.test-bottm {
    z-index: 10000 !important;
}



.crete-disc-toolbar {
    //padding-bottom: 10px;
    margin: 10px;
}

.search-filter {

    min-width: 200px;
    padding: 20px;


    .p-field-radiobutton {
        margin-bottom: 0px;
    }

    .filter-label {
         font: normal normal normal 16px/19px Roboto;

        letter-spacing: -0.38px;
        color: #7E7E7E;
        opacity: 0.87;
    }
}


// .tab-active-p1 {
//     border-bottom: 3px solid var(--primary-color);
// }


.sort-title1 {
    font: normal normal 600 22px/30px Nunito Sans;
    // letter-spacing: -0.53px;
    // color: #1d1d1d;
    //font: normal normal normal 14px/19px Nunito Sans;
    letter-spacing: 0px;
    color: #CECECE;
    margin-left: 20px;

}

.tab-active-p1 {
    color: var(--primary-color);
}

.tab-active-p1:after {
    content: "";
    display: block;
    // margin: 0 auto;
    width: 50%;
    padding-top: 0px;
    border-bottom: 3px solid var(--primary-color);
}


.answer-menu {
    padding: 10px;
    width: auto;


    .action-item {
        cursor: pointer;


    }

    li {
        padding: 8px;
    }

    .li-img-icon {
        height: 16px;
        width: 16px;
        margin-top: -3px;
        margin-right: 4px;

        // margin-bottom: 10px;
    }


}



.li-item-text {
    position: relative;
   // top: -5px;
    padding-left: 5px;
    font: normal normal normal 16px/19px Roboto;
    letter-spacing: -0.38px;
    color: #929292;
    cursor: pointer;
}