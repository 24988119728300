.usage-analytics {}



.highlights-tabs {
  //  margin-top: 10px;
    //  text-align: center;
    text-align:justify;
    

    .highlights-tab {
        padding: 10px;
        //text-align:justify;
        // width:200px;

        p {
            margin: 5px;
        }


        .h-title {
            margin: 0px;
            padding: 0px;
            font: normal normal normal 18px/24px ;
            font-weight: bold;
            letter-spacing: 0px;
            color: #242424;

        }


        .h-time-l {
            font: normal normal 600 12px/17px ;
            letter-spacing: 0px;
            color: #242424;
            opacity: 0.32;


        }

        span {
            font: normal normal normal 20px/27px ;
            font-weight: bold;
            letter-spacing: 0px;
            color: var(--primary-color);
            opacity: 1;
        }
    }



}

.analyitcs-filters {
    //min-width: 300px;
    width: 660px;

    .filter-title {
        margin-top: 0px;
        //font: normal normal normal 14px/19px Nunito Sans;
        font-family: Nunito Sans;
        letter-spacing: 0px;
        font-weight: bold;
        color: #333333;
        
    }


    .filter-sub {
        font: normal normal normal 16px Poppins;
        letter-spacing: 0px;
        color: #7E7E7E;
        margin-bottom: 0px;
    }

    .table-filter {
        // min-width: 200px;
        padding: 20px;

        li {
            margin-bottom: 5px;
        }
    }
}


.total-stats {
    margin-top: 10px;
    margin-right: 20px;

    .total-time {
        margin: 0px;
        font: normal normal normal 20px/30px Poppins;
        font-weight: bold;
        letter-spacing: 0.13px;
        color: var(--primary-color);

        span {
            font: normal normal normal 16px/30px Poppins;
            letter-spacing: 0.1px;
            color: #3A3A3A;
        }
    }

    .total-users {}

}




.graph-view {


    .refresh-analytics {
        font-size: 20px;
        font-weight: bold;
        color: var(--primary-color);
    }


    .analytics-graph {
        margin-top: 20px;

        .hours-graph-bar {
            width: 90%;
            margin: auto;

            border: 1px solid var(--theme-80);
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            height: 400px;
           // width: 100%;


            .no-data {
                text-align: center;
                padding-top: 150px;
                font: normal normal 600 20px/27px Nunito Sans;
            }
        }


        .hours-graph-pie {
            width: 90%;
            margin: auto;
            border: 1px solid var(--theme-80);
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            height: 400px;
//            width: 100%;

            .no-data {
                text-align: center;
                padding-top: 150px;
                font: normal normal 600 20px/27px Nunito Sans;
            }
        }
    }
}


.usage-analytics {
    .graph-c {
        margin-top: 50px;

        .time-spent-c {
            padding: 15px;

            .time-spent-label {
                font: normal normal 600 20px/27px Nunito Sans;
                letter-spacing: 0px;
                color: #5c5c5c;
                opacity: 1;
            }

            .time-spent-filter {
                float: right;
            }

            .bar-graphs {
                height: 340px;
                //mar-bottom: -30px;
            }

            .no-data {
                text-align: center;
                padding-top: 150px;
            }

            p {
                padding-left: 15px;
                font: normal normal 600 20px/20px Nunito Sans;
                letter-spacing: 0px;
                color: #5c5c5c;

                span {
                    font: normal normal normal 15px/20px Nunito Sans;
                }
            }
        }

        .resource-usage-c {
            padding: 15px;

            .bar-graphs {
                height: 340px;

                .no-data {
                    text-align: center;
                    padding-top: 150px;
                }
            }

            p {
                padding-left: 15px;
                font: normal normal 600 20px/20px Nunito Sans;
                letter-spacing: 0px;
                color: #5c5c5c;

                span {
                    font: normal normal normal 15px/20px Nunito Sans;
                }
            }
        }
    }

    .filters-c {
        padding: 10px;
        background-color: var(--theme-97);

        p {
            margin: 0px;
        }

        .c-daterange {
            // .rdrDateDisplayWrapper {

            //     background-color: white !important;

            // }
            // .rdrStartEdge, .rdrEndEdge, .rdrInRange {
            //     color: red !important;
            // }
            // .rdrDayHovered  {
            //     border-color: red !important;
            // }
        }
    }

    .filter-btn {
        border: none;
        padding: 5px 26px;
        letter-spacing: 0px;
        text-align: center;
        text-decoration: none;
        font: normal normal 600 15px/20px Open Sans;
        letter-spacing: 0.09px;
        color: #343434;
        background-color: transparent;
        cursor: pointer;
        border-right: 3px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        //border-radius: 6px;
    }

    .filter-btn-select {
        border: none;
        padding: 5px 26px;
        letter-spacing: 0px;
        text-align: center;
        text-decoration: none;
        font: normal normal 600 15px/20px Open Sans;
        letter-spacing: 0.09px;
        color: #343434;
        background-color: transparent;
        cursor: pointer;
        width: 100%;
    }

    .date-range {
        font: normal normal 600 15px/20px Open Sans;
        letter-spacing: 0.09px;
        color: #343434;
        padding: 8px 26px;

        .date-range-t {
            //   font-size: 10px;
        }
    }

    .active-fiter-btn {
        background-color: #fff;
        border-left: 3px solid var(--primary-color);
        border-right: 3px solid var(--primary-color);
    }

    .a-btn {
        border: none;
        color: white;
        padding: 5px 26px;
        letter-spacing: 0px;
        text-align: center;
        text-decoration: none;
        font: normal normal bold 12px/16px Nunito Sans;
        background-color: var(--primary-color);
        cursor: pointer;
        border-radius: 6px;
        float: right;

        .arrow-f {
            margin-left: 10px;
            font-size: 16px;
        }
    }

    .calender-icon {
        float: right;
    }
}

.date-tooltip {
    position: relative;
    display: inline-block;
    //border-bottom: 1px dotted black;
    width: 100%;
    padding: 3px 26px;
}

.date-tooltip .date-tooltiptext {
    visibility: hidden;
    width: 120px;
    //  background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -60px;
}

.date-tooltip:hover .date-tooltiptext {
    visibility: visible;
}




.usage-analytics {
    .graph-c {
        margin-top: 50px;

        .time-spent-c {
            padding: 15px;

            .time-spent-label {
                font: normal normal 600 20px/27px Nunito Sans;
                letter-spacing: 0px;
                color: #5c5c5c;
                opacity: 1;
            }

            .time-spent-filter {
                float: right;
            }

            .bar-graphs {
                height: 340px;
                //mar-bottom: -30px;
            }

            .no-data {
                text-align: center;
                padding-top: 150px;
            }

            p {
                padding-left: 15px;
                font: normal normal 600 20px/20px Nunito Sans;
                letter-spacing: 0px;
                color: #5c5c5c;

                span {
                    font: normal normal normal 15px/20px Nunito Sans;
                }
            }
        }

        .resource-usage-c {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 20px;
            padding: 15px;

            .bar-graphs {
                height: 340px;

                .no-data {
                    text-align: center;
                    padding-top: 150px;
                }
            }

            p {
                padding-left: 15px;
                font: normal normal 600 20px/20px Nunito Sans;
                letter-spacing: 0px;
                color: #5c5c5c;

                span {
                    font: normal normal normal 15px/20px Nunito Sans;
                }
            }
        }
    }

    .filters-c {
        padding: 10px;
        background-color: var(--theme-97);

        p {
            margin: 0px;
        }

        .c-daterange {
            // .rdrDateDisplayWrapper {

            //     background-color: white !important;

            // }
            // .rdrStartEdge, .rdrEndEdge, .rdrInRange {
            //     color: red !important;
            // }
            // .rdrDayHovered  {
            //     border-color: red !important;
            // }
        }
    }

    .filter-btn {
        border: none;
        padding: 5px 26px;
        letter-spacing: 0px;
        text-align: center;
        text-decoration: none;
        font: normal normal 600 15px/20px Open Sans;
        letter-spacing: 0.09px;
        color: #343434;
        background-color: transparent;
        cursor: pointer;
        border-right: 3px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        //border-radius: 6px;
    }

    .filter-btn-select {
        border: none;
        padding: 5px 26px;
        letter-spacing: 0px;
        text-align: center;
        text-decoration: none;
        font: normal normal 600 15px/20px Open Sans;
        letter-spacing: 0.09px;
        color: #343434;
        background-color: transparent;
        cursor: pointer;
        width: 100%;
    }

    .date-range {
        font: normal normal 600 15px/20px Open Sans;
        letter-spacing: 0.09px;
        color: #343434;
        padding: 8px 26px;

        .date-range-t {
            //   font-size: 10px;
        }
    }

    .active-fiter-btn {
        background-color: #fff;
        border-left: 3px solid var(--primary-color);
        border-right: 3px solid var(--primary-color);
    }

    .a-btn {
        border: none;
        color: white;
        padding: 5px 26px;
        letter-spacing: 0px;
        text-align: center;
        text-decoration: none;
        font: normal normal bold 12px/16px Nunito Sans;
        background-color: var(--primary-color);
        cursor: pointer;
        border-radius: 6px;
        float: right;

        .arrow-f {
            margin-left: 10px;
            font-size: 16px;
        }
    }

    .calender-icon {
        float: right;
    }
}

.date-tooltip {
    position: relative;
    display: inline-block;
    //border-bottom: 1px dotted black;
    width: 100%;
    padding: 3px 26px;
}

.date-tooltip .date-tooltiptext {
    visibility: hidden;
    width: 120px;
    //  background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -60px;
}

.date-tooltip:hover .date-tooltiptext {
    visibility: visible;
}


.content-type {
    position: relative;
    top: 10px;
}

.search-users-txt {
    font: normal normal normal 14px/21px Poppins;
    font-weight: 600;
    margin: 0px;
    letter-spacing: 0.09px;
}
