.assign-content {


    .selct-s {

        font: normal normal bold 18px/22px Nunito Sans;
        letter-spacing: 0px;
        color: #333333;
    }
}


.ma-required-mark {
    color: #ff0000;
}