.Montserrat24 {
    font: normal normal normal 14px/21px Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    letter-spacing: 0.25px;
    text-align: left;

}

.poppins24 {
    font: normal normal normal 24px/36px Poppins;
    letter-spacing: 0.25px;
    text-align: left;
    color: #979797;
    font-weight: 400;
    font-size: 24px;

}

.addButton {
    font: normal normal normal 16px/24px Inter;
    font-weight: 500;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
}

.repText {
    font: normal normal normal 12px/16px InterSemiBold;
    font-weight: 500;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
}

.inter500semibold {
    font: normal normal normal 12px/16px InterSemiBold;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
    color: black;
}

.interRegular14 {
    font: normal normal normal 14px/21px InterRegular;
    font-weight: 400;
    letter-spacing: 0.25px;
    text-align: left;
}