
.custom-input-group {
    border-radius: 6px;
    overflow: hidden;  /* Ensure that the border radius is applied to the whole container */
    border: 1px solid #ced4da; /* Default border color */
    background: white; /* Ensure the background is white */
    // transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.custom-input-group.custom-input-focused {
    border:2px solid #8ec7f3 !important; /* Light blue border color */
    box-shadow: 0 0 0 0.2rem #cce4f6 !important; /* Increased shadow with light blue color */
    border-radius: 6px;
}

.custom-input-group .p-inputgroup-addon {
    background-color: transparent;
    border: none;
}

  
.border-set{
    border-radius: 12px;
    border: 1px solid "lightgrey";
}

.custom-input-group .p-inputtext:focus,
.custom-input-group .p-dropdown:focus,
.custom-input-group .p-multiselect:focus,
.custom-input-group .p-calendar:focus {
    outline: none;
    box-shadow: none;
}
.no-border:focus {
    border: none !important;
    box-shadow: none !important;
  }
  .border-calendar {
    border: none !important;
    box-shadow: none !important;
}


.addButtonTextForm {
    font: normal normal normal 16px/24px InterSemiBold !important;
    letter-spacing: 0.3px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    text-align: center !important;
   
}

.addButtonForm {
   
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    width: 188px;
    height: 48px;
    background: #076EFF;
    //box-shadow: 0px 8px 12px -2px rgba(0, 0, 0, 0.08), 0px 2px 6px -2px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.cancelButtonForm {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    width: 182px;
    height: 48px;
    background: #F4F5F6;
    border-radius: 12px;
    flex: none;
    margin-top: 15px!important;
   
}

.cancelButtonForm:hover {
    background: #dadada!important; /* Ensure background color stays the same on hover */
}

.cancelButtonTextForm {
    font: normal normal normal 16px/24px InterSemiBold !important;
    width: 55px;
    height: 24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #171C1F;
    flex: none;
    order: 0;
    flex-grow: 0;


}

.dateTextLabelForm {
    //styleName: Body Small / Semibold;
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.25px;
    text-align: left;
    gap: 0px;
    
    color: #000000;

}