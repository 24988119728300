.Montserrat24 {
    font: normal normal normal 14px/21px Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    letter-spacing: 0.25px;
    text-align: left;

}

.poppins24 {
    font: normal normal normal 24px/36px Poppins !important;
    letter-spacing: 0.25px;
    text-align: left;
    color: #979797;

}

.addButtonClass {
    font: normal normal normal 16px/24px InterRegular !important;
    font-weight: 500;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
}

.sideHeading {
    font: normal normal normal 16px/24px InterSemiBold;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    color: black;
}

.topicName {
    font: normal normal normal 16px/24px InterRegular !important;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-align: left;

}

.interSemibold {
    font: normal normal normal 18px/26px InterSemiBold !important;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.5px;
    text-align: left;
}

.interbold {
    font: normal normal normal 20px/24px InterBold !important;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
}

.selectedResource {
    padding: '0px 24px 0px 24px';
    background-color: '#F8F9FA';
}

.resourceTitle {
    font: normal normal normal 16px/24px InterRegular !important;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-align: left;
}

.addformatText {
    font: normal normal normal 20px/24px InterRegular !important;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
}

.uploadResourceText {
    font: normal normal normal 18px/24px InterSemiBold !important;
    font-weight: 500;
    letter-spacing: 0.25px;
    text-align: left;
}

.inter24Text {
    font: normal normal normal 24px/24px InterSemiBold;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-align: left;
}