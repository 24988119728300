.button-group .p-component {
    border-radius: 16px;
    width: 349px;
    height: 48px;
    border: 1px solid #C6CED2;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.04)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.08));
}



.button-group .p-button {
    padding: 12px 16px;
    gap: 8px;
}

.button-group .p-highlight {
    background-color: #F4F5F6 !important;
    border: 1px solid #E6E8EA !important;
    border: 1px solid #C6CED2 !important;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.04)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.08)) !important;
}

.button-group .p-button .p-focus {
    background-color: #F4F5F6 !important;
    border: 1px solid #E6E8EA !important;
    border: 1px solid #C6CED2 !important;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.04)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.08)) !important;
}

.button-group .p-button {
    padding: 12px 16px;

}

.button-group .p-button-label {
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    letter-spacing: 0.3px;
    line-height: 24px;
    font-family: 'InterSemiBold';
    font-style: normal;
}

.custom-fee .p-button {
    box-shadow: 0px 8px 12px -2px rgba(0, 0, 0, 0.08), 0px 2px 6px -2px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    background: #076EFF;
    width: 228px;
    height: 56px;
}

.custom-fee .custom-fee-add {
    font-family: 'InterRegular';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #FFFFFF;

}

.custom-fee-pay .p-button {
    padding: 12px;
    width: 85px;
    height: 40px;
    box-shadow: 0px 8px 12px -2px rgba(0, 0, 0, 0.08), 0px 2px 6px -2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;

}

.custom-fee-pay .payButton {

    font-family: 'InterRegular';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #FFFFFF;


}


.dataTableValuesText .p-datatable-thead>tr>th {
    background-color: #FFFFFF !important;
    border: none !important;
    border-bottom: 1px solid #F4F5F6 !important;
    font-family: 'InterSemiBold';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: #4E616B;

}

.dataTableValuesText .p-datatable-tbody>tr>td {

    // padding: 16px 24px;
    width: 166.33px;
    height: 84px;
    background: #FFFFFF;
    border: none !important;
    gap: 16px;
    border-bottom: 1px solid #F4F5F6 !important;
    font-family: 'InterSemiBold';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.5px;

}

.pay-fee {
    position: relative;
    width: 400px;
    height: 700px;
    background: #FFFFFF;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.pay-fee .formhead {
    font-family: 'InterSemiBold';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #000000;

}

.pay-fee .p-dialog-footer .formSubmitButton {
    padding: 12px;
    width: 286px;
    height: 48px;
    background-color: #076EFF;
    box-shadow: 0px 8px 12px -2px rgba(0, 0, 0, 0.08), 0px 2px 6px -2px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
}

.pay-fee .p-dialog-footer .formCancelButton {
    background-color: #F4F5F6;
    border-radius: 12px;
    padding: 12px;
    width: 182px;
    height: 48px;
}

.fieldHeight {
    height: 46px;
    width: 660px;
}

.searchInputText {
    //styleName: Body Medium/Regular;
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #000000 !important;
    width: 400px;
    height: 46px;

}

.assignFeeCard {
    background: var(--background-elevation-2, #FFFFFF);
    width: 1214px;
    height: 280px;
    top: 286px;
    left: 122px;
    gap: var(--space-300);
    border-radius: 20px;

    box-shadow: -2px -2px 3px 2px #0000000A inset;
    box-shadow: 2px 2px 4px 1px #00000040 inset;


}

.cardProfile {
    width: 570px;
    border-right: 2px solid rgb(195, 191, 191);
    height: 278px;
    margin-top: -16px;

}

.cardSubProfile {
    width: 492px;
    height: 194px;
    gap: var(--space-0);
    border: 0px 0px 1px 0px;


}

.cardSubProfileText {
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: left;
    color: #000000;

}

// .imageSize{
//     width: 48px;
// height: 48px;
// gap: 0px;
// border-radius: 30px;
// 
// border: 1px solid var(--White-100, #FFFFFF)
// }
.feeCategory {
    width: 644px;
    height: 278px;

}

.cardSubProfileBoldText {
    //styleName: Body Medium / Bold;
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #000000;
    width: 117px;
    height: 24px;

}

.cardSubProfileParaText {
    // font: normal normal normal 10px/14px InterSemiBold;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.25px;
    text-align: left;
    color: #4E616B;

}

.boardText {
    //styleName: Body Medium/Regular;
    font: normal normal normal 10px/14px InterSemiBold;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;

}

.assignFeeBtn {
    width: 224px;
    height: 56px;
    padding: 5px 10px;
    gap: var(--space-0);
    border-radius: 12px;

    background: var(--components-button-primary-background-default, #076EFF);
    //styleName: Action Large/Semibold;
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: center;
    // On click
    // Navigate to: "Finance/ Assign Fee";
    // Animate: Smart animate;
    animation-timing-function: ease-out;
    animation-duration: 300ms;




}

.assignFeeDropdownContainer {
    width: 343px;
    height: 100px;
    gap: var(--space-100);


}

.assignFeeDropdown {
    width: 343px;
    height: 48px;
    gap: 0px;
    border-radius: 8px;

    box-shadow: 0px 1px 2px 0px #00000014;


}

.assignFeeSaveBtn {
    width: 83px;
    height: 56px;
    padding: var(--space-200);
    gap: var(--space-0);
    border-radius: 13px;

    box-shadow: 0px 2px 6px -2px #0000000F;
    // On click
    // Navigate to: "Finance/ Assigned Fee Category";
    // Animate: Smart animate;
    //styleName: Action Large/Semibold;
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: center;

    animation-timing-function: ease-out;
    animation-duration: 300ms;

    box-shadow: 0px 8px 12px -2px #00000014;

    background: var(--components-button-primary-background-default, #076EFF);

}

.tableSaveBtn {
    width: 62px;
    height: 40px;
    padding: var(--space-150);
    gap: var(--space-0);
    border-radius: 8px;

    background: var(--components-button-primary-background-default, #076EFF);
    // On click
    // Open overlay: "Cookies Alert";
    // Animate: Instant;
    animation-duration: 0ms;
    //styleName: Action Small/Semibold;
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.4000000059604645px;
    text-align: center;
    color: #FFFFFF;

}

.calenderBoxSize {
    width: 400px;
    height: 530px;
    padding: var(--space-300);
    gap: var(--space-150);
    border-radius: 12px;

    background: var(--background-elevation-2, #FFFFFF);
    box-shadow: 0px 4px 8px -2px #0000000A;

    box-shadow: 0px 16px 24px -4px #00000014;

}

.calenderApplyBtn {
    width: 136px;
    height: 40px;
    gap: var(--space-0);
    border-radius: 12px;

    background: var(--components-button-primary-background-default, #076EFF);

    //styleName: Action Small/Semibold;
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.4000000059604645px;
    text-align: center;
    color: #FFFFFF;
    box-shadow: 0px 2px 6px -2px #0000000F;

    box-shadow: 0px 8px 12px -2px #00000014;

}

.calenderCancelBtn {
    width: 136px;
    height: 40px;
    gap: var(--space-0);
    border-radius: 12px;

    background: var(--components-button-secondary-background-default, #F4F5F6);
    //styleName: Action Small/Semibold;
    font: normal normal normal 10px/14px InterSemiBold;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    border: none;
    letter-spacing: 0.4000000059604645px;
    text-align: center;
    color: #171C1F;
    box-shadow: 0px 2px 6px -2px #0000000F;

    box-shadow: 0px 8px 12px -2px #00000014;

}


.concession-amt {
    font-family: 'InterRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    letter-spacing: 0.5px;

    color: #000000;

}

.input-field .p-input-icon-left>.p-inputtext {
    font-family: 'InterRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #000000;
    border: none;
    padding-left: 35px;
}

.input-field .p-input-icon-left {
    border-radius: 8px;
    border: 1px solid #E6E8EA;
    background-color: '#E6E8EA';
}

.assign-fee-category-btn {
    width: 224px;
    height: 56px;
    border-radius: 200;
    background: var(--components-button-primary-background-default, #076EFF);
    box-shadow: 0px 2px 6px -2px #0000000F;
    box-shadow: 0px 8px 12px -2px #00000014;
    animation-timing-function: ease-out;
    animation-duration: 300ms;
    font: normal normal normal normal InterSemiBold;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: center;
    color: #FFFFFF;
}

.termBasedHeading {
    font: normal normal normal normal InterSemiBold;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: center;
    color: #000000;

}

.payBtn {
    font: normal normal normal 16px/24px InterSemiBold !important;
    // font-family: 'InterRegular';
    font-style: normal;
    font-weight: 500;

    color: #FFFFFF;
}

.receipt-container {
    page-break-after: always;
    margin-top: 15px;
}