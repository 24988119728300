.generalsetting-card {
    background: #ffffff;
    padding: 1.25rem;
    margin-left: 25px;
    margin-right: 25px;
    border: 0 none;
    margin-bottom: 2rem;
    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25), inset -2px -2px 3px 2px rgba(0, 0, 0, 0.04);
    border-radius: 24px;
}
.card-head {
    color: #000000;
    font: normal normal normal 24px InterBold !important;
    font-size: 24px;
    font-weight: 800;
    line-height: 24.2px;
}
